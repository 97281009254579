import React from "react";
import styled from "styled-components";
import { useUserContext } from "../UserProvider";

const Wrapper = styled.div`
  width: 100%;
  padding: 30px 10px;
  @media (min-width: 768px) {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    padding: 30px 0px;
    border-top: 1px solid #ddd;
    position: fixed;
    left: 50%;
    transform: translatex(-50%);
    bottom: 0;
    background: ${({ theme }) => theme.colors.light};
  }
`;
const Section = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large};
  :not(:first-child) {
    margin-top: 15px;
  }
  @media (min-width: 768px) {
    :not(:first-child) {
      margin-top: 0;
    }
  }
`;
const SectionHeading = styled.div`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSize.large};
  line-height: calc(${({ theme }) => theme.fontSize.large} * 1.5);
`;
const OnlyXS = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`;
const NotXS = styled.span`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;
const Link = styled.div`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
`;

export default function Footer() {
  const { user } = useUserContext();

  const URL =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/room/" +
    user.roomKey;

  const copyURL = () => {
    const el = document.createElement("textarea");
    el.value = URL;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };

  return (
    <Wrapper>
      <Section>
        <SectionHeading>Your Sessions URL</SectionHeading>
        <div>
          {URL}
          <OnlyXS>
            <Link onClick={copyURL}>Copy Link</Link>
          </OnlyXS>
        </div>
      </Section>
      <Section>
        <OnlyXS>
          <SectionHeading>Add to your phone</SectionHeading>
        </OnlyXS>
        <div>
          Come here quickly with one click access.
          <NotXS>
            <Link>Bookmark this page</Link>
          </NotXS>
          <OnlyXS>
            <Link>Add to your home screen</Link>
          </OnlyXS>
        </div>
      </Section>
    </Wrapper>
  );
}
