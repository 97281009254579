import React, { useState, createContext, useContext } from "react";

// Valid user states
export const userStates = {
  WAITING: "waiting",
  REQUIRE_CHECKIN: "require_checkin",
  IN_SESSION: "in_session",
};

export const UserContext = createContext({});

export function UserProvider({ type, children }) {
  const [user, setUser] = useState({
    type,
    authorized: false,
    roomKey: "",
    displayName: "",
    photoUrl: "",
    countryCode: "",
  });

  const [sessionToken, setSessionToken] = useState("");

  const getRoomUrl = () =>
    `${window.location.protocol}//${window.location.hostname}/room/${user.roomKey}`;

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        sessionToken,
        setSessionToken,
        getRoomUrl,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("userUserContext must be used within a UserProvider");
  }

  return context;
}
