/**
 * The Link component is intended to be used in place of an <a> tag when there
 * is no valid href to navigate to. It can be used to render a modal or other
 * pop-up action on the page without the need to update the url
 */
import styled from "styled-components";

const Link = styled.button`
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-decoration: none;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;
export default Link;
