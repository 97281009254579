import React from "react";
import Select from "../../Elements/Select";
import useVideoContext from "../../../hooks/useVideoContext";
import { useAudioInputDevices } from "../../../hooks/useDeviceHooks";
import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle";
import { isdef } from "../../../utils";

export default function SelectVideoTrack({ setter }) {
  const {
    localTracks,
    room: { localParticipant },
    getLocalAudioTrack,
  } = useVideoContext();
  const audioInputDevices = useAudioInputDevices();
  const [isAudioEnabled] = useLocalAudioToggle();

  const localAudioTrack = localTracks.find(track => track.kind === "audio");
  const localAudioInputDeviceId = isdef(localAudioTrack)
    ? localAudioTrack.mediaStreamTrack.getSettings().deviceId
    : "";

  const replaceTrack = newDeviceId => {
    if (isdef(localAudioTrack)) {
      localAudioTrack.stop();
    }
    getLocalAudioTrack({ deviceId: newDeviceId }).then(newTrack => {
      if (isdef(localAudioTrack)) {
        if (isdef(localParticipant)) {
          const localTrackPublication = localParticipant.unpublishTrack(
            localAudioTrack,
          );
          localParticipant.emit("trackUnpublished", localTrackPublication);
          localParticipant.publishTrack(newTrack);
          if (!isAudioEnabled) {
            newTrack.disable();
          }
        }
      }
    });
  };

  return (
    <Select
      label="Microphone"
      id="selectAudioInputDevice"
      value={localAudioInputDeviceId}
      options={audioInputDevices.map(d => ({
        value: d.deviceId,
        text: d.label,
      }))}
      onChange={e => replaceTrack(e.target.value)}
    />
  );
}
