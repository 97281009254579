import styled from "styled-components";

const ListItemWrapper = styled.div`
  width: calc(50% - 10px);
  @media (min-width: 768px) {
    width: calc(33.3% - 10px);
  }
  margin-bottom: 15px;
`;

export default ListItemWrapper;
