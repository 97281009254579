import React, { useRef } from "react";
import useTrack from "../../hooks/useTrack";
import AudioTrack from "../AudioTrack/";
import VideoTrack from "../VideoTrack/VideoTrack";

export default function Publication({
  publication,
  isLocal,
  disableAudio,
  videoPriority,
}) {
  const track = useTrack(publication);

  const videoRef = useRef(null);

  if (!track) return null;

  switch (track.kind) {
    case "video":
      return (
        <VideoTrack
          track={track}
          priority={videoPriority}
          isLocal={track.name.includes("camera") && isLocal}
          videoRef={videoRef}
        />
      );
    case "audio":
      return disableAudio ? null : <AudioTrack track={track} />;
    default:
      return null;
  }
}
