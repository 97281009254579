import React from "react";
import styled from "styled-components";

import Header from "../components/Header";
import ProfileImage from "../components/ProfileImage";
import { useWaitingRoomContext } from "../components/WaitingRoomProvider";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 50px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const MessageWrapper = styled.div`
  padding: 0 10px;

  h3 {
    margin: 0 0 3px;
  }

  p {
    margin: 0;
    font-style: italic;
  }
`;

function ClientExitRoomView() {
  const { room } = useWaitingRoomContext();

  return (
    <div>
      <Header />
      <Body>
        <Wrapper>
          <ProfileImage src={room.therapistPhotoUrl} />
          <MessageWrapper>
            <h3>Thank You!</h3>
            <p>Your session has been terminated.</p>
          </MessageWrapper>
        </Wrapper>
      </Body>
    </div>
  );
}
export default ClientExitRoomView;
