import React, { useState } from "react";
import styled from "styled-components";

import {
  WaitingRoomToggleButton,
  VideoToggleButton,
  AudioToggleButton,
  ExitButton,
} from "./Buttons";
import ElapsedTime from "./ElapsedTime";
import WaitingRoom from "./WaitingRoom";
import ConfirmationModal from "../ConfirmationModal";
import OverflowMenu from "./OverflowMenu";
import VideoAndMicSettings from "../VideoAndMicSettings";
import useWaitingParticipant from "../../hooks/useWaitingParticipants";
import useVideoContext from "../../hooks/useVideoContext";
import useWindowSize from "../../hooks/useWindowSize";
import useCopy from "../../hooks/useCopy";
import { requestEndSession } from "../../httpapi";

const ControlWrap = styled.div`
  display: flex;
  box-shadow: ${({ theme }) => theme.darkBoxShadow};
`;
const PaddedWrapper = styled.div`
  padding: 30px 0;
  @media (min-width: 768px) {
    padding: 20px 0;
  }
`;

const ControlGroup = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;
  align-items: center;

  &:first-of-type:not(:last-of-type) {
    justify-content: flex-start;
    text-align: left;
  }

  &:last-of-type:not(:first-of-type) {
    justify-content: flex-end;
  }
`;

const TimerWrapper = styled.div`
  padding: 0 20px;
`;

const ControlItem = styled.div`
  width: 55px;
  margin: 0 10px;
`;

const PoweredWrapper = styled.div`
  margin-top: 7px;
  font-size: 0.8em;
  color: ${({ theme }) => theme.colors.grey};
`;

function SessionControls({ isAdmin }) {
  const [waitingRoomOpen, setWaitingRoomOpen] = useState(false);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [changingMediaSettings, setChangingMediaSettings] = useState(false);
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState(false);
  const { waitingParticipants, getQueueLength } = useWaitingParticipant();
  const closeMediaSettingsModal = () => setChangingMediaSettings(false);
  const { room } = useVideoContext();
  const { width } = useWindowSize();
  const [
    poweredByText,
    confirmEndSessionText,
    confirmEndSessionTitle,
  ] = useCopy([
    "callscreen.poweredby",
    "callscreen.confirmEndSession",
    "callscreen.confirmEndSessionTitle",
  ]);

  const exitHandler = () => {
    if (isAdmin) {
      setExitConfirmationOpen(true);
    } else {
      room.disconnect();
    }
  };

  const adminButton = (
    <ControlGroup>
      <ControlItem>
        <WaitingRoomToggleButton
          waitingCount={getQueueLength()}
          onClick={e => setWaitingRoomOpen(!waitingRoomOpen)}
        />
      </ControlItem>
      {waitingRoomOpen ? (
        <WaitingRoom
          participants={waitingParticipants}
          onClose={() => setWaitingRoomOpen(false)}
        />
      ) : null}
    </ControlGroup>
  );

  return (
    <>
      <ControlWrap>
        {isAdmin ? adminButton : null}
        {width > 767 ? (
          <ControlGroup>
            <TimerWrapper>
              {isAdmin ? <ElapsedTime /> : null}
              <PoweredWrapper>{poweredByText}</PoweredWrapper>
            </TimerWrapper>
          </ControlGroup>
        ) : null}
        <PaddedWrapper>
          <ControlGroup>
            <ControlItem>
              <VideoToggleButton
                enabled={videoEnabled}
                onClick={e => setVideoEnabled(!videoEnabled)}
              />
            </ControlItem>
            <ControlItem>
              <AudioToggleButton
                enabled={audioEnabled}
                onClick={e => setAudioEnabled(!audioEnabled)}
              />
            </ControlItem>
            <ControlItem>
              <ExitButton onClick={exitHandler} />
            </ControlItem>
          </ControlGroup>
        </PaddedWrapper>
        <OverflowMenu
          onClick={() => setChangingMediaSettings(!changingMediaSettings)}
        />
      </ControlWrap>
      {changingMediaSettings && (
        <VideoAndMicSettings close={closeMediaSettingsModal} />
      )}
      {exitConfirmationOpen && (
        <ConfirmationModal
          title={confirmEndSessionTitle}
          onConfirm={requestEndSession}
          onClose={() => {
            setExitConfirmationOpen(false);
          }}
        >
          {confirmEndSessionText}
        </ConfirmationModal>
      )}
    </>
  );
}

export default SessionControls;
