import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";

import mutedIcon from "../../assets/muted-mic-icon-white.svg";

const Wrapper = styled.div`
  width: 20px;
  height: 20px;
  background: ${({ theme }) => theme.colors.red};
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 50%;
  border: none;
  display: flex;
  justify-content: center;

  img {
    display: block;
    width: 60%;
    user-select: none;
    -webkit-user-drag: none;
  }
`;

export default function Muted({ identity }) {
  const [mutedText] = useCopy(["callscreen.muted"]);
  return (
    <Wrapper id={`participantMuted-${identity}`}>
      <img src={mutedIcon} alt={mutedText} />
    </Wrapper>
  );
}
