import styled, { css } from "styled-components";
import variants from "./variants";

const Button = styled.button`
  display: inline-block;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-weight: normal;
  transition: all 0.2s ease-in-out;
  border: 1px solid ${({ theme }) => theme.colors.border};
  background: ${({ theme }) => theme.colors.primaryBlue};
  color: white;
  &:hover {
    border-color: ${({ theme }) => theme.colors.borderDark};
  }

  font-size: 14px;
  min-width: 125px;
  padding: 12px;
  width: ${({ full }) => (full ? "100%" : "auto")};
  @media (max-width: 576px) {
    width: ${({ retainWidthOnMobile }) =>
      retainWidthOnMobile ? "inherit" : "100%"};
  }

  outline: none;

  &:active {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `};

  ${({ working }) =>
    working &&
    css`
      opacity: 0.75;
    `};
  ${({ inline }) =>
    inline &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0px;
    `};
  ${({ variant }) => variant && variants[variant]}
`;

export default Button;
