import React, { useState, useEffect } from "react";
import useVideoContext from "../hooks/useVideoContext";
import useRoomState from "../hooks/useRoomState";
import VideoSession from "../components/VideoSession";
import useTherapistMuted from "../hooks/useTherapistMuted";
import useJoinedSession from "../hooks/useJoinedSession";
import useSessionEnded from "../hooks/useSessionEnded";

const states = {
  INITIALIZING: 0,
  CONNECTING: 1,
  CONNECTED: 2,
};

function Session({ isAdmin, token, onDisconnect }) {
  const [state, setState] = useState(0);
  const { connect } = useVideoContext();
  const roomState = useRoomState();

  useTherapistMuted();
  useJoinedSession();
  useSessionEnded(onDisconnect);

  // initial state, try to connect to video room
  useEffect(() => {
    if (state === states.INITIALIZING) {
      console.log("connecting");
      connect(token);
      setState(states.CONNECTING);
    }
  }, [state, token, connect]);

  // when we know the room is connected, update the state
  useEffect(() => {
    if (roomState === "connected") {
      setState(states.CONNECTED);
    }
  }, [roomState]);

  return state === states.CONNECTED ? <VideoSession isAdmin={isAdmin} /> : null;
}

export default Session;
