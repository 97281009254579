import React, { useState } from "react";
import styled from "styled-components";

import useInterval from "../../hooks/useInterval";
import { useWebsocketContext } from "../WebsocketProvider";
import useCopy from "../../hooks/useCopy";

const TimeWrapper = styled.div`
  margin-top: 3px;
  font-weight: 900;
  font-size: 1em;
`;

const TimeComponent = styled.div`
  display: inline-block;
  width: ${({ seconds }) => (seconds > 3600 ? 80 : 60)}px;
`;

const Time = ({ seconds }) => (
  <TimeComponent seconds={seconds}>{parseTime(seconds)}</TimeComponent>
);

const parseTime = seconds => {
  if (!seconds) {
    return "00:00";
  }
  const date = new Date(0);
  date.setSeconds(seconds);
  if (seconds >= 3600) {
    return date.toISOString().substr(11, 8);
  }
  return date.toISOString().substr(14, 5);
};

const calcTime = d => Math.floor((new Date() - d) / 1000);

function ElapsedTime() {
  const { sessionStartTime } = useWebsocketContext();
  const [elapsedTime, setElapsedTime] = useState(
    sessionStartTime ? calcTime(sessionStartTime) : null,
  );

  const [elapsedTimeText] = useCopy(["callscreen.elapsedtime"]);

  useInterval(() => {
    if (sessionStartTime) {
      const timeSince = calcTime(sessionStartTime);
      if (timeSince !== elapsedTime) {
        setElapsedTime(timeSince);
      }
    }
  }, 1000);

  return (
    <TimeWrapper>
      <Time seconds={elapsedTime} />
      {elapsedTimeText}
    </TimeWrapper>
  );
}

export default ElapsedTime;
