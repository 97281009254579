import React from "react";
import { useUserContext } from "../UserProvider";
import { requestIdentityVerification } from "../../httpapi";
import getIdentityToken from "../../auth";

const authorize = (onAuthSuccess, onAuthFailure) => async token => {
  // request server verification
  const result = await requestIdentityVerification(token);

  // callback to success
  if (result.success) {
    return onAuthSuccess(result);
  }

  // callback with failure;
  return onAuthFailure();
};

// Authenticated requests application authentication via the
// the identity token.
export default function Authenticated({ children }) {
  const token = getIdentityToken();
  const { user, setUser } = useUserContext();

  // if we"re not authorized execute the authorization flow
  if (!user.authorized) {
    authorize(
      result => {
        setUser(state => {
          return {
            ...state,
            authorized: true,
            roomKey: result.roomKey,
            displayName: result.displayName,
            photoUrl: result.photoUrl,
            countryCode: result.countryCode,
          };
        });
      },
      () => {
        window.location = `${
          process.env.REACT_APP_MEMBER_WEB
        }?url=${encodeURIComponent(window.location)}`;
      },
    )(token);
    // render nothing if not authorized
    return null;
  }

  // render when the above passes
  return <>{children}</>;
}
