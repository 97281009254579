/**
 * get the copy required for the invite to session modal
 */
import useCopy from "../../hooks/useCopy.js";

export default () =>
  useCopy([
    "dashboard.invitemodal.title",
    "dashboard.invitemodal.nameLabel",
    "dashboard.invitemodal.emailLabel",
    "dashboard.invitemodal.startTimeLabel",
    "dashboard.invitemodal.noStartTime",
    "dashboard.invitemodal.messageTitle",
    "dashboard.invitemodal.messagePlaceholder",
    "dashboard.invitemodal.buttonText",
    "dashboard.invitemodal.defaultMessage",
    "dashboard.invitemodal.noStartTimeMessage",
    "global.copyInvitationURL",
  ]);
