import { useEffect } from "react";
import { useWebsocketContext } from "../components/WebsocketProvider";
import useLocalAudioToggle from "./useLocalAudioToggle";

export default function useTherapistMuted() {
  const { lastJsonMessage } = useWebsocketContext();
  const [, toggleAudioEnabled] = useLocalAudioToggle();

  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    if (lastJsonMessage.message === "mute") {
      toggleAudioEnabled();
    }
  }, [lastJsonMessage, toggleAudioEnabled]);
}
