import styled from "styled-components";

const Anchor = styled.a`
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primaryBlue};
  text-decoration: none;
  background: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:visited {
    color: ${({ theme }) => theme.colors.primaryBlue};
  }
`;
export default Anchor;
