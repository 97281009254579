import React from "react";
import ParticipantTracks from "../ParticipantTracks/";
import useMainSpeaker from "../../hooks/useMainSpeaker";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import useScreenShareParticipant from "../../hooks/useScreenShareParticipant";

export default function MainParticipant() {
  const mainParticipant = useMainSpeaker();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  const videoPriority =
    mainParticipant === selectedParticipant ||
    mainParticipant === screenShareParticipant
      ? "high"
      : null;
  return (
    /* audio is disabled for this participant component because this participant's audio
       is already being rendered in the <ParticipantList /> component.  */
    <ParticipantTracks
      participant={mainParticipant}
      disableAudio
      enableScreenShare
      videoPriority={videoPriority}
    />
  );
}
