import React, { useState } from "react";
import styled from "styled-components";
import Modal from "./TOSModal";
import Link from "../Elements/Link";
import Checkbox from "../Elements/Checkbox";

const Label = styled.span`
  margin-left: 10px;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

export default function TermsOfService({ disabled, checked, toggle }) {
  const [showModal, setShowModal] = useState(false);
  const open = () => setShowModal(true);
  const close = () => setShowModal(false);
  return (
    <div style={{ margin: "5px 0" }}>
      <Checkbox
        id="tosAgreement"
        disabled={disabled}
        checked={checked}
        onChange={toggle}
      />
      <Label>
        I agree to the{" "}
        <Link type="button" onClick={open}>
          terms of service
        </Link>
      </Label>

      {showModal && <Modal close={close} />}
    </div>
  );
}
