import React, { useEffect } from "react";
import styled, { css } from "styled-components";

const Video = styled.video`
  display: block;
  width: 100%;
  max-height: 100%;
  object-fit: cover;

  ${({ isLocal }) =>
    isLocal &&
    css`
      transform: rotateY(180deg);
    `};
`;

export default function VideoTrack({ videoRef, track, isLocal, priority }) {
  useEffect(() => {
    const el = videoRef.current;
    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }
    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null);
      }
    };
  }, [videoRef, track, priority]);

  return <Video ref={videoRef} isLocal={isLocal} />;
}
