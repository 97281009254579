import React from "react";
import Button, { SubmitButton } from "../Elements/Button";
import styled from "styled-components";

const FooterWrapper = styled.div`
  text-align: right;
  > * {
    margin-left: 10px;
  }
`;

export default function Footer({ close, onJoin }) {
  return (
    <FooterWrapper>
      <Button onClick={close} variant="light">
        Cancel
      </Button>
      <SubmitButton onClick={onJoin}>Join Now</SubmitButton>
    </FooterWrapper>
  );
}
