import React from "react";
import styled from "styled-components";

const gutter = "10px";
const Wrapper = styled.div`
  width: 100%;
  & > div {
    margin-bottom: ${gutter};
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: ${({ justify }) => justify};
    & > div {
      margin-right: ${gutter};
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

const Child = styled.div`
  flex-basis: ${({ basis }) => basis};
`;

const Flex = ({ children, basis = [], justify = "space-between" }) => {
  const isBasisProvided = basis.length > 0;
  const defaultBasis = `${100 / children.length}%`;

  return (
    <Wrapper justify={justify}>
      {children.map((child, i) => (
        <Child key={i} basis={isBasisProvided ? basis[i] : defaultBasis}>
          {child}
        </Child>
      ))}
    </Wrapper>
  );
};

export default Flex;
