import styled from "styled-components";

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => justify};
  margin: 0;
  padding: 0;
  border-radius: 3px;
  font-family: ${({ theme }) => theme.fonts.secondary};
`;

export default List;
