import React, { useCallback } from "react";

import { useUserContext } from "../components/UserProvider";
import Session from "../pages/SessionView";
import Dashboard from "../components/Dashboard";
import useSessionReady from "../hooks/useSessionReady";

function TherapistRoomView() {
  const { user } = useUserContext();
  const { token, setToken } = useSessionReady();

  const onDisconnect = useCallback(() => {
    setToken("");
  }, [setToken]);

  if (token === "") {
    return <Dashboard name={user.displayName} photoUrl={user.photoUrl} />;
  }

  if (token !== "") {
    return <Session token={token} isAdmin={true} onDisconnect={onDisconnect} />;
  }
}

export default TherapistRoomView;
