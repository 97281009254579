import React from "react";
import styled from "styled-components";
import MainParticipant from "../MainParticipant";
import ParticipantList from "../ParticipantList";
import SessionControls from "../SessionControls";
import useWindowSize from "../../hooks/useWindowSize";
import { NotificationProvider } from "../Notifications";
import InSessionNotifications from "./InSessionNotifications";

const PageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => height + "px"};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.default};
`;

const ParticipantListWrap = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  box-shadow: 2px 2px 10px 0px ${({ theme }) => theme.colors.greyOverlay};

  @media (min-width: 768px) {
    width: 200px;
    max-height: 85vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const VideoWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1 1 auto;

  & video {
    height: 100%;
    position: absolute;
  }
`;

const MenuWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 9;

  @media (min-width: 768px) {
    background: white;
    position: static;
    flex: 0 0 auto;
  }
`;

export default function ({ isAdmin }) {
  const { height } = useWindowSize();

  return (
    <NotificationProvider>
      <InSessionNotifications isAdmin={isAdmin} />
      <PageWrap height={height}>
        <VideoWrap>
          <MainParticipant />
        </VideoWrap>
        <ParticipantListWrap>
          <ParticipantList isAdmin={isAdmin} />
        </ParticipantListWrap>
        <MenuWrap>
          <SessionControls isAdmin={isAdmin} />
        </MenuWrap>
      </PageWrap>
    </NotificationProvider>
  );
}
