import React from "react";
import styled from "styled-components";
import Header from "../Header";
import InviteToSessionPanel from "../InviteToSessionPanel";
import ClientList from "../ClientList";
import Footer from "./Footer";

const Body = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 10px 0;
  @media (min-width: 768px) {
    padding: 30px 0 100px;
    flex-direction: row;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  > * {
    margin-bottom: 30px;
  }
`;

export default function Dashboard({ name, photoUrl }) {
  return (
    <>
      <Header isTherapist={true} />
      <Body>
        <Content>
          <InviteToSessionPanel name={name} photoUrl={photoUrl} />
          <ClientList />
        </Content>
      </Body>
      <Footer />
    </>
  );
}
