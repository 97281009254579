export const isMobile = (() => {
  if (
    typeof navigator === "undefined" ||
    typeof navigator.userAgent !== "string"
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

// This function ensures that the user has granted the browser permission to use audio and video
// devices. If permission has not been granted, it will cause the browser to ask for permission
// for audio and video at the same time (as opposed to separate requests).
export function ensureMediaPermissions() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then(devices =>
      devices.every(device => !(device.deviceId && device.label)),
    )
    .then(shouldAskForMediaPermissions => {
      if (shouldAskForMediaPermissions) {
        return navigator.mediaDevices
          .getUserMedia({ audio: true, video: true })
          .then(mediaStream =>
            mediaStream.getTracks().forEach(track => track.stop()),
          );
      }
    });
}

export const isUndefined = val => typeof val === "undefined";
export const isdef = val => !isUndefined(val);
export const getLocalAudioTrack = tracks =>
  tracks.find(t => t.kind === "audio");

export const checkElementOverlap = (elem1, elem2) => {
  const rect1 = elem1.getBoundingClientRect();
  const rect2 = elem2.getBoundingClientRect();
  return !(
    rect1.right < rect2.left ||
    rect1.left > rect2.right ||
    rect1.bottom < rect2.top ||
    rect1.top > rect2.bottom
  );
};
