import React from "react";
import VideoTrack from "../VideoTrack/VideoTrack";
import useVideoContext from "../../hooks/useVideoContext";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  margin-bottom: 60px;
  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const AspectRatio = styled.div`
  overflow: hidden;
  padding-top: 140%;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 0;
  }
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.default};

  video {
    width: auto;
    min-height: 100%;
    max-height: 140%;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    position: static;
    width: 420px;
    height: 315px;

    video {
      width: inherit;
      max-height: inherit;
      max-width: inherit;
    }
  }
`;

const EmptyVideo = styled.div`
  display: block;
  width: 100%;
  height: 100%;
  background: #000;
  @media (min-width: 768px) {
    max-height: inherit;
    max-width: inherit;
    width: 420px;
    height: 315px;
  }
`;

export default function LocalVideoPreview({ videoRef }) {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(track => track.name.includes("camera"));

  return (
    <Wrapper>
      <AspectRatio>
        <VideoWrapper>
          {videoTrack ? (
            <VideoTrack videoRef={videoRef} track={videoTrack} isLocal />
          ) : (
            <EmptyVideo />
          )}
        </VideoWrapper>
      </AspectRatio>
    </Wrapper>
  );
}
