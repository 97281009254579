import React, { useState } from "react";
import styled from "styled-components";
import { H2 } from "../Elements/Headings";
import ProfileImage from "../ProfileImage";
import { SubmitButton } from "../Elements/Button";
import TextInput from "../Elements/TextInput";
import TermsOfService from "../TermsOfService";
import { requestClientAuthorization } from "../../httpapi";
import { useUserContext } from "../UserProvider";
import { useWaitingRoomContext } from "../WaitingRoomProvider";

const FormWrapper = styled.form`
  display: grid;
  width: 100%;
  grid-template-columns: 0fr;
  grid-template-rows: 0fr 0fr;
`;

const PictureWrapper = styled.div`
  grid-column: span 1;
  grid-row: span 2;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Header = styled.div`
  grid-column: 2 / span 1;
  grid-row: span 1;
  margin-bottom: 15px;
  line-height: 1;
`;

const Prompt = styled.p`
  grid-column: 2 / span 1;
  grid-row: span 1;
  font-size: 14px;
  margin: 0 0 20px 0;
  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

const InputWrapper = styled.div`
  grid-column: span 2;

  @media (min-width: 768px) {
    grid-column: 2 / span 1;
  }
`;

const FormItemWrapper = styled.div`
  margin-bottom: 20px;
`;

function CheckInView({ videoRef, canvasRef }) {
  const { room } = useWaitingRoomContext();
  const { setUser } = useUserContext();
  const [busy, setBusy] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);

  const handleDisplayNameChange = e => setDisplayName(e.target.value);
  const toggleTermsAgreement = () => setHasAgreedToTerms(!hasAgreedToTerms);
  const disabled = () =>
    !displayName.match(/[\p{L}]{2,32}/u) || !hasAgreedToTerms || busy;
  const renderButtonText = () => (busy ? "Checking in..." : "Join Session");

  // Render a video frame and capture the client's photo so the therapist can
  // visually identify the client.
  // TODO: While unlikely, it's possible that the video metadata won't be
  // available at this point.
  const capturePhoto = (video, canvas) => {
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    return canvas.toDataURL("image/png");
  };

  const checkin = async (roomID, name, photo) => {
    const succeeded = await requestClientAuthorization(roomID, name, photo);
    if (succeeded) {
      setUser(state => {
        return {
          ...state,
          authorized: true,
          displayName: name,
        };
      });
    }
  };

  // handle the button submission and orchestrate the checkin steps.
  const handleSubmit = async evt => {
    evt.preventDefault();
    setBusy(true);
    const photo = capturePhoto(videoRef.current, canvasRef.current);
    checkin(room.roomId, displayName, photo);
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <PictureWrapper>
        <ProfileImage src={room.therapistPhotoUrl} alt="todo therapist name" />
      </PictureWrapper>
      <Header>
        <H2>Welcome!</H2>
      </Header>
      <Prompt>
        Before we get started, enter your name and agree to the terms of
        service.
      </Prompt>
      <InputWrapper>
        <FormItemWrapper>
          <TextInput
            onChange={handleDisplayNameChange}
            placeholder="Please enter your name"
            maxlength="32"
          />
        </FormItemWrapper>
        <FormItemWrapper>
          <TermsOfService
            checked={hasAgreedToTerms}
            toggle={toggleTermsAgreement}
            disabled={busy}
          />
        </FormItemWrapper>
        <FormItemWrapper>
          <SubmitButton id="checkinClient" disabled={disabled()} full>
            {renderButtonText()}
          </SubmitButton>
        </FormItemWrapper>
      </InputWrapper>
    </FormWrapper>
  );
}

export default CheckInView;
