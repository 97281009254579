// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
export default {
  video: { height: 720, frameRate: 24, width: 1280 },
  bandwidthProfile: {
    video: {
      mode: "grid",
      maxTracks: 10,
      dominantSpeakerPriority: "standard",
      renderDimensions: {
        high: { height: 1080, width: 1980 },
        standard: { height: 720, width: 1280 },
        low: { height: 176, width: 144 },
      },
    },
  },
  dominantSpeaker: true,
  maxAudioBitrate: 16000,
  networkQuality: { local: 1, remote: 1 },
  // preferredVideoCodecs: [{ codec: "VP8", simulcast: false }],
};
