import React, { useState } from "react";
import styled from "styled-components";
import TextInput from "../Elements/TextInput";
import Checkbox from "../Elements/Checkbox";
import Flex from "../Elements/Flex";
import DayAndTimeDropdown from "../DayAndTimeDropdown";
import MessageArea from "./MessageArea";
import getCopy from "./inviteToSessionCopy.js";
import Footer from "./footer";

import { useUserContext } from "../UserProvider";

import Modal from "../Modal";

const Spacer = styled.div`
  margin-bottom: 20px;
`;
const Label = styled.span`
  margin-left: 10px;
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 17px;
  }
`;

const InviteToSessionModal = ({ close }) => {
  const [
    title,
    nameLabel,
    emailLabel,
    startTimeLabel,
    noStartTimeText,
    messageTitle,
    messagePlaceholder,
    buttonText,
    defaultMessage,
    noStartTimeMessage,
    copyInvitationURL,
  ] = getCopy();

  const { user, getRoomUrl } = useUserContext();

  const getMessageHTML = message => ({
    __html: message
      .replace(
        "[APPOINTMENT_TIME]",
        selectedDate.toLocaleString([], {
          year: "numeric",
          day: "numeric",
          month: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }),
      )
      .replace("[ROOM_URL]", getRoomUrl())
      .replace("[THERAPIST_NAME]", user.displayName),
  });

  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [noDateAndTime, setNoDateAndTime] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const messagePrefix = noDateAndTime ? noStartTimeMessage : defaultMessage;

  const submit = () => {
    fetch("/url", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        clientName,
        clientEmail,
        message: `${
          getMessageHTML(messagePrefix).__html
        }<p>${customMessage}</p>`,
      }),
    });
    close();
  };
  const canSend = clientName !== "" && clientEmail !== "";
  return (
    <Modal
      maxWidth="900px"
      close={close}
      title={title}
      footer={Footer({
        disabled: !canSend,
        copyRoomText: copyInvitationURL,
        buttonText,
        send: submit,
      })}
    >
      <Flex basis={["45%", "55%"]}>
        <div>
          <Spacer>
            <TextInput
              onChange={e => setClientName(e.target.value)}
              label={nameLabel}
            />
          </Spacer>
          <Spacer>
            <TextInput
              onChange={e => setClientEmail(e.target.value)}
              label={emailLabel}
            />
          </Spacer>
          <Spacer>
            <DayAndTimeDropdown
              disabled={noDateAndTime}
              date={selectedDate}
              label={startTimeLabel}
              onChange={setSelectedDate}
            />
          </Spacer>
          <Spacer>
            <Checkbox
              checked={noDateAndTime}
              onClick={() => setNoDateAndTime(!noDateAndTime)}
            />
            <Label onClick={() => setNoDateAndTime(!noDateAndTime)}>
              {noStartTimeText}
            </Label>
          </Spacer>
        </div>
        <div>
          <MessageArea
            placeholder={messagePlaceholder}
            title={messageTitle}
            message={getMessageHTML(messagePrefix)}
            onChange={setCustomMessage}
          />
        </div>
      </Flex>
    </Modal>
  );
};

export default InviteToSessionModal;
