import React from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import Button from "../Elements/Button";

import Modal from "../Modal";
import Tabs from "../Tabs";

import micIcon from "../../assets/active-mic-icon.svg";
import videoIcon from "../../assets/active-camera-icon.svg";

import {
  SelectVideoTrack,
  SelectAudioInputTrack,
  SelectAudioOutputTrack,
} from "../SessionControls/MediaSelection";

const SelectItem = styled.div`
  margin-bottom: 15px;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AudioPanel = () => {
  return (
    <>
      <SelectItem>
        <SelectAudioInputTrack />
      </SelectItem>
      <SelectItem>
        <SelectAudioOutputTrack />
      </SelectItem>
    </>
  );
};
const VideoPanel = () => {
  return (
    <div>
      <SelectVideoTrack publish />
    </div>
  );
};
const Icon = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 5px;
  background-image: url("${({ src }) => src}");
  background-size: contain;
  background-repeat: no-repeat;
`;
const IconHeader = ({ icon, text, style }) => {
  return (
    <div style={{ display: "flex", ...style }}>
      <Icon src={icon} />
      {text}
    </div>
  );
};
const Wrapper = styled.div`
  text-align: left;
  @media (min-width: 768px) {
    min-width: 400px;
  }
`;
const Body = () => {
  return (
    <Wrapper>
      <Tabs
        panels={[
          [
            <IconHeader
              icon={micIcon}
              text="Audio"
              style={{ alignItems: "center" }}
            />,
            <AudioPanel />,
          ],
          [
            <IconHeader
              icon={videoIcon}
              text="Video"
              style={{ alignItems: "flex-end" }}
            />,
            <VideoPanel />,
          ],
        ]}
      />
    </Wrapper>
  );
};

export default function VideoAndMicSettings({ close, ...rest }) {
  const [title] = useCopy(["callscreen.changeInputs.title"]);
  const renderFooter = () => {
    return (
      <FooterWrapper>
        <Button onClick={close} id="confirmationModalConfirm">
          Done
        </Button>
      </FooterWrapper>
    );
  };
  return (
    <Modal
      variant="light"
      footer={renderFooter()}
      close={close}
      title={title}
      {...rest}
    >
      <Body />
    </Modal>
  );
}
