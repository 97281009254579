import React, { useState, createContext, useContext } from "react";

export const WaitingRoomContext = createContext({});

export function WaitingRoomProvider({ children }) {
  const [room, setRoom] = useState({
    roomKey: "",
    roomId: "",
    therapistDisplayName: "",
    therapistPhotoUrl: "",
    countryCode: "",
  });

  return (
    <WaitingRoomContext.Provider
      value={{
        room,
        setRoom,
      }}
    >
      {children}
    </WaitingRoomContext.Provider>
  );
}

export function useWaitingRoomContext() {
  const context = useContext(WaitingRoomContext);
  if (!context) {
    throw new Error(
      "useWaitingRoomContext must be used with a WaitingRoomProvider",
    );
  }

  return context;
}
