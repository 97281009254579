import React from "react";
import styled from "styled-components";
import ParticipantTracks from "../ParticipantTracks";
import InitialsBadge from "./InitialsBadge";
import Muted from "./Muted";
import Controls from "./Controls";
import usePublications from "../../hooks/usePublications";
import useIsTrackEnabled from "../../hooks/useIsTrackEnabled";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import useTrack from "../../hooks/useTrack";
import useHoverOrTouch from "../../hooks/useHoverOrTouch";
import {
  requestMuteParticipant,
  requestDeleteParticipant,
} from "../../httpapi";

const Container = styled.div`
  margin: 0 5px;

  @media (min-width: 768px) {
    margin: 5px 0;
  }
`;

const VideoWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  width: 50px;
  padding-top: 140%;

  border: 2px solid
    ${({ isSelected, theme }) => (isSelected ? theme.colors.green : "white")};

  @media (min-width: 768px) {
    width: 100%;
    /* round down 16x9 aspect ratio from 56.25 to prevent
    rounding issues causing empty pixel at bottom of video */
    padding-top: 56%;
  }
`;

const Video = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.default};
  display: flex;
  align-items: center;
  justify-content: center;

  /*
  TODO: more work to be done on this ratio.
  Also used in LocalVideoPreview, but with a different
  desktop ratio. Move to its own component when we nail that down.
   */
  video {
    width: auto;
    min-height: 100%;
    max-height: 140%;
    max-width: 100%;
  }
`;

const DisplayName = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    font-size: 15px;
    padding-top: 5px;
  }
`;

const name = identity => identity.split("|")[0];

export default function Participant({
  participant,
  disableAudio,
  enableScreenShare,
  showAdminControls,
}) {
  const [hoverRef, isHovering] = useHoverOrTouch();
  const [
    selectedParticipant,
    setSelectedParticipant,
  ] = useSelectedParticipant();

  const publications = usePublications(participant);
  const videoPublication = publications.find(p =>
    p.trackName.includes("camera"),
  );
  const isVideoEnabled = Boolean(videoPublication);
  const audioPublication = publications.find(p => p.kind === "audio");
  const audioTrack = useTrack(audioPublication);
  const isAudioEnabled = useIsTrackEnabled(audioTrack);
  const muteParticipant = async identity => {
    const result = await requestMuteParticipant(identity);
    if (!result) {
      console.error(`Error muting audio for participant ${identity}`);
    }
    return result;
  };
  const removeParticipant = async identity => {
    const result = await requestDeleteParticipant(identity);
    if (!result) {
      console.error(`Error removing participant ${identity}`);
    }
    return result;
  };

  const displayName = name(participant.identity);

  return (
    <Container>
      <VideoWrap
        isSelected={selectedParticipant === participant}
        ref={hoverRef}
      >
        <Video>
          <ParticipantTracks
            participant={participant}
            disableAudio={disableAudio}
            enableScreenShare={enableScreenShare}
            videoPriority="low"
          />
          {!isVideoEnabled && <InitialsBadge name={displayName} />}
        </Video>
        <Controls
          isVisible={isHovering}
          showAdminControls={showAdminControls}
          isSelected={selectedParticipant === participant}
          setSelected={() => setSelectedParticipant(participant)}
          isAudioEnabled={isAudioEnabled}
          muteParticipant={() => muteParticipant(participant.identity)}
          removeParticipant={() => removeParticipant(participant.identity)}
          identity={participant.identity}
        />
        {!isAudioEnabled && <Muted identity={participant.identity} />}
      </VideoWrap>
      <DisplayName>{displayName}</DisplayName>
    </Container>
  );
}
