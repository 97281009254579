import React from "react";
import { useWaitingRoomContext } from "../WaitingRoomProvider";
import { requestWaitingRoomDetails } from "../../httpapi";
import { useParams, useHistory } from "react-router-dom";

const getWaitingRoomDetails = (onSuccess, onFailure) => async roomKey => {
  // get room details from server
  const result = await requestWaitingRoomDetails(roomKey);

  // callback to success
  if (result.success) {
    return onSuccess(result);
  }

  // callback with failure;
  return onFailure();
};

// WaitingRoomDetails requests room details and stores them in the WaitingRoomContext
export default function WaitingRoomDetails({ children }) {
  const history = useHistory();
  const { roomKey } = useParams();
  const { room, setRoom } = useWaitingRoomContext();

  if (room.roomKey !== roomKey) {
    getWaitingRoomDetails(
      result => {
        setRoom({
          roomKey,
          roomId: result.roomId,
          therapistDisplayName: result.therapistDisplayName,
          therapistPhotoUrl: result.therapistPhotoUrl,
          countryCode: result.countryCode,
        });
      },
      () => {
        history.push(`/room/${roomKey}/disabled`);
      },
    )(roomKey);
  }

  // render when the above passes
  return <>{children}</>;
}
