import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import micIcon from "../../assets/active-mic-icon-white.svg";
import endCallIcon from "../../assets/end-call-icon.svg";
import pinIcon from "../../assets/pin-icon.svg";
import useCopy from "../../hooks/useCopy";

const Wrapper = styled.div`
  position: absolute;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  margin: 0 auto;
  z-index: 1;

  ${({ isVisible }) =>
    isVisible &&
    css`
      display: flex;
    `};
`;

const ControlItem = styled.button`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.greyOverlay};
  border: none;
  border-radius: 20px;
  height: 28px;
  font-size: 13px;
  color: white;
  margin: 2px 0;
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: default;
  }

  span {
    display: none;
    margin-left: 5px;
  }

  img {
    width: 13px;
    display: block;
    user-select: none;
    -webkit-user-drag: none;
  }

  @media (min-width: 768px) {
    span {
      display: block;
    }
  }
`;

export default function Controls({
  showAdminControls,
  isVisible,
  isSelected,
  setSelected,
  isAudioEnabled,
  muteParticipant,
  removeParticipant,
  identity,
}) {
  const [muteText, removeText, pinText, unpinText] = useCopy([
    "callscreen.mute",
    "callscreen.remove",
    "callscreen.pin",
    "callscreen.unpin",
  ]);
  const controlsRef = useRef(null);
  const [busy, setBusy] = useState(false);
  const makeBusy = fn => {
    return async () => {
      setBusy(true);
      await fn();
      if (controlsRef.current) {
        setBusy(false);
      }
    };
  };

  return (
    <Wrapper isVisible={isVisible} ref={controlsRef}>
      {showAdminControls && (
        <>
          {isAudioEnabled && (
            <ControlItem
              onClick={makeBusy(muteParticipant)}
              disabled={busy}
              id={`participantControlMute-${identity}`}
            >
              <img src={micIcon} alt={muteText} />
              <span>{muteText}</span>
            </ControlItem>
          )}
          <ControlItem
            onClick={makeBusy(removeParticipant)}
            disabled={busy}
            id={`participantControlRemove-${identity}`}
          >
            <img src={endCallIcon} alt={removeText} />
            <span>{removeText}</span>
          </ControlItem>
        </>
      )}
      <ControlItem
        onClick={setSelected}
        id={`participantControlPin-${identity}`}
      >
        <img src={pinIcon} alt={pinText} />
        <span>{isSelected ? unpinText : pinText}</span>
      </ControlItem>
    </Wrapper>
  );
}
