import React from "react";
import Label from "../Label";

import styled, { css } from "styled-components";
const Input = styled.input`
  border-right: 0;
  display: block;
  height: 50px;
  appearance: none;
  color: ${({ theme }) => theme.colors.default};
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 16px;
  line-height: 1.3;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.light : theme.colors.white};
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 9px 13px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.defaultLight};
  }
  ${({ inline }) =>
    inline &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0px;
    `};
`;

Input.defaultProps = {
  type: "text",
  inline: false,
};

const TextInput = ({ label, ...rest }) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <Input {...rest} />
    </>
  );
};

export default TextInput;
