export default {
  boxShadow: "2px 2px 10px 0px rgba(0,0,0,0.2);",
  darkBoxShadow: "2px 2px 10px 0px rgba(0,0,0,0.6);",
  colors: {
    default: "#2c2d30",
    light: "#f5f7f9",
    border: "#dddddd",
    borderHighlighted: "#c4c4c4",
    borderDark: "#bbbbbb",
    primaryBlue: "#477be4",
    darkBlue: "#3A66C0",
    green: "#84ce7b",
    gray: "#737E90",
    red: "#ff5754",
    shadow: "rgba(44, 45, 48, 0.3)",
    greyOverlay: "rgba(44, 45, 48, 0.6)",
  },
  fonts: {
    primary: "'Proxima Nova Regular', sans-serif",
    bold: "'Proxima Nova Bold', sans-serif",
    semiBold: "'Proxima Nova Semi Bold', sans-serif",
  },
  fontSize: {
    small: "13px",
    normal: "14px",
    large: "17px",
  },
};
