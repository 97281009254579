/**
 * useCopy returns an array of copy values when given an array of copy keys.
 * These values can be destructured in a component when a localized string is
 * needed.
 *
 * ```
 * const [title, placeholder] = useCopy(["copyKey.title", "copyKey.placeholder"]);
 * ```
 */
import copy from "../copy";

export default keys => keys.map(key => copy[key]);
