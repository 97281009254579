import React, { useState } from "react";
import styled from "styled-components";
import { H2 } from "../Elements/Headings";
import ProfileImage from "../ProfileImage";
import { SubmitButton } from "../Elements/Button";
import Break from "../Elements/Break";
import Panel from "../Panel";

import InviteToSessionModal from "../InviteToSessionModal";

const Content = styled.div`
  margin-left: 10px;
  width: 100%;
  flex: 3 0px;
`;

const ButtonRow = styled.div`
  margin-top: 15px;
  width: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const IntroText = styled.div`
  margin-top: 5px;
`;

function InviteToSessionPanel({ name, photoUrl }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {showModal && <InviteToSessionModal close={() => setShowModal(false)} />}
      <Panel>
        <Wrapper>
          <ProfileImage src={photoUrl} />
          <Content>
            <H2>Hello, {name}</H2>
            <IntroText>
              With Sessions by Psychology Today you can easily meet with your
              clients online. Start by arranging a session now!
            </IntroText>
          </Content>
          <Break />
          <ButtonRow>
            <SubmitButton onClick={() => setShowModal(true)} full>
              Invite Client to Session
            </SubmitButton>
          </ButtonRow>
        </Wrapper>
      </Panel>
    </>
  );
}

export default InviteToSessionPanel;
