import React from "react";
import Select from "../../Elements/Select";
import useVideoContext from "../../../hooks/useVideoContext";
import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle";
import { useVideoInputDevices } from "../../../hooks/useDeviceHooks";
import { isdef } from "../../../utils";

export default function SelectVideoTrack() {
  const {
    localTracks,
    getLocalVideoTrack,
    currentVideoDevice,
    room: { localParticipant },
  } = useVideoContext();
  const videoInputDevices = useVideoInputDevices();
  const [isVideoEnabled] = useLocalVideoToggle();

  const localVideoTrack = localTracks.find(track =>
    track.name.includes("camera"),
  );

  function replaceTrack(newDeviceId) {
    if (isdef(localVideoTrack)) {
      localVideoTrack.stop();
    }
    getLocalVideoTrack({
      publish: isVideoEnabled,
      deviceId: { exact: newDeviceId },
    }).then(newTrack => {
      if (isdef(localVideoTrack)) {
        if (isdef(localParticipant)) {
          const localTrackPublication = localParticipant.unpublishTrack(
            localVideoTrack,
          );
          localParticipant.emit("trackUnpublished", localTrackPublication);
        }
      }
      if (isVideoEnabled) {
        if (isdef(localParticipant)) {
          localParticipant.publishTrack(newTrack);
        }
      }
    });
  }

  return (
    <Select
      label="Video"
      id="selectVideoDevice"
      options={videoInputDevices.map(d => ({
        value: d.deviceId,
        text: d.label,
      }))}
      value={currentVideoDevice}
      onChange={e => replaceTrack(e.target.value)}
    />
  );
}
