import React from "react";
import styled from "styled-components";

const padding = 20;

const Wrapper = styled.div`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.border};
  font-size: 17px;
  line-height: 22px;
  border-radius: 3px;
`;
const Header = styled.div`
  padding: ${padding}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
`;
const Body = styled.div`
  padding: ${padding}px;
`;
const Footer = styled.div`
  padding: ${padding}px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

function Panel({ children, header, subheader, footer }) {
  return (
    <Wrapper>
      {header && <Header>{header}</Header>}
      {subheader && <Header>{subheader}</Header>}
      <Body>{children}</Body>
      {footer && <Footer>{footer}</Footer>}
    </Wrapper>
  );
}

export default Panel;
