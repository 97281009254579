import styled from "styled-components";

const Break = styled.div`
  flex-basis: 100%;
  height: 0;
  border: 0;
  margin: 0;
`;

export default Break;
