import React from "react";
import styled from "styled-components";
import { SubmitButton } from "../Elements/Button";
import CopyRoomUrl from "../CopyRoomUrl";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export default ({ disabled, copyRoomText, buttonText, send }) => {
  return (
    <Wrapper>
      <CopyRoomUrl text={copyRoomText} />
      <SubmitButton disabled={disabled} onClick={send} retainWidthOnMobile>
        {buttonText}
      </SubmitButton>
    </Wrapper>
  );
};
