import styled from "styled-components";

const Label = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  margin-bottom: 3px;
  text-align: left;
`;

export default Label;
