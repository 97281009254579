import useVideoContext from "./useVideoContext";
import { useEffect } from "react";

export default function useSessionEnded(onDisconnect) {
  const { room } = useVideoContext();
  useEffect(() => {
    room.on("disconnected", onDisconnect);
    return () => room.off("disconnect", onDisconnect);
  }, [room, onDisconnect]);
}
