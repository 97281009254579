const actions = {
  AUTHORIZE_THERAPIST: "therapist",
  ROOM_DETAILS: "room",
  CHECKIN: "checkin",
  CREATE_SESSION: "create-session",
  END_SESSION: "delete-session",
  MUTE: "mute-participant",
  ADD_PARTICIPANTS: "add-participants",
  DELETE_PARTICIPANT: "delete-participant",
};

const baseURL = process.env.REACT_APP_API_URL;

const xhrRequest = async ({ method, action, token, body }) => {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest();
    const url = baseURL.replace(/\/?$/, "/") + action;
    xhr.open(method, url);
    if (token) {
      xhr.setRequestHeader("Authorization", "Bearer " + token);
    }
    xhr.setRequestHeader("Content-Type", "application/json; utf-8");
    xhr.withCredentials = true;
    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    if (body) {
      xhr.send(body);
    } else {
      xhr.send();
    }
  });
};

const requestWaitingRoomDetails = async roomKey => {
  const res = {
    success: true,
    roomId: "",
    therapistDisplayName: "",
    therapistPhotoUrl: "",
    countryCode: "",
  };
  try {
    const response = await xhrRequest({
      method: "GET",
      action: actions.ROOM_DETAILS + `/${roomKey}`,
    });
    const json = JSON.parse(response);
    res.roomId = json.room_id;
    res.therapistDisplayName = json.therapist_display_name;
    res.therapistPhotoUrl = json.therapist_photo_url;
    res.countryCode = json.country_code;
  } catch (e) {
    res.success = false;
  }
  return res;
};

const requestClientAuthorization = async (roomId, identity, picture) => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.CHECKIN,
      body: JSON.stringify({
        room_id: roomId,
        display_name: identity,
        picture_data: picture,
      }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestMuteParticipant = async identity => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.MUTE,
      body: JSON.stringify({ identity }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestStartSession = async identities => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.CREATE_SESSION,
      body: JSON.stringify({
        participants: identities,
      }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestIdentityVerification = async token => {
  const res = {
    success: true,
    roomKey: "",
    displayName: "",
    photoUrl: "",
    countryCode: "",
  };
  try {
    const response = await xhrRequest({
      method: "POST",
      action: actions.AUTHORIZE_THERAPIST,
      token: token,
    });
    const json = JSON.parse(response);
    res.roomKey = json.room_key;
    res.displayName = json.display_name;
    res.photoUrl = json.photo_url;
    res.countryCode = json.country_code;
  } catch (e) {
    res.success = false;
  }
  return res;
};

const requestEndSession = async () => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.END_SESSION,
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestAddParticipants = async identities => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.ADD_PARTICIPANTS,
      body: JSON.stringify({
        participants: identities,
      }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

const requestDeleteParticipant = async identity => {
  try {
    await xhrRequest({
      method: "POST",
      action: actions.DELETE_PARTICIPANT,
      body: JSON.stringify({
        identity,
      }),
    });
    return true;
  } catch (e) {
    return false;
  }
};

export {
  requestIdentityVerification,
  requestWaitingRoomDetails,
  requestClientAuthorization,
  requestStartSession,
  requestMuteParticipant,
  requestEndSession,
  requestAddParticipants,
  requestDeleteParticipant,
};
