import React from "react";
import Select from "../../Elements/Select";
import { useAudioOutputDevices } from "../../../hooks/useDeviceHooks";
import useVideoContext from "../../../hooks/useVideoContext";

export default function SelectAudioOutputTrack() {
  const audioOutputDevices = useAudioOutputDevices();
  const { activeSinkId, setActiveSinkId } = useVideoContext();

  return (
    <>
      <Select
        label="Speakers"
        id="selectAudioOutputDevice"
        value={activeSinkId}
        options={audioOutputDevices.map(d => ({
          value: d.deviceId,
          text: d.label,
        }))}
        onChange={e => setActiveSinkId(e.target.value)}
      />
    </>
  );
}
