import React from "react";
import IconButton from "./IconButton";
import Counter from "./Counter";

import icon from "../../../assets/waiting-room-icon.svg";

export default function WaitingRoomToggleButton({ onClick, waitingCount }) {
  return (
    <IconButton
      onClick={onClick}
      id="toggleWaitingRoom"
      tooltip="Show Waiting Room"
    >
      {waitingCount ? (
        <Counter id="waitingRoomCount">{waitingCount}</Counter>
      ) : null}
      <img src={icon} alt="Show Waiting Room" />
    </IconButton>
  );
}
