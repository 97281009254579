import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { NotificationContext } from "./index";

const Wrapper = styled(Snackbar)`
  && {
    padding: 0;
    bottom: 110px;
    text-align: center;
    min-width: 0;
  }
  .MuiSnackbarContent-message {
    width: 100%;
    padding: 0;
  }
`;
const Content = styled(SnackbarContent)`
  && {
    padding: 5px 10px;
    background-color: ${({ theme }) => theme.colors.greyOverlay};
    min-width: 0;
    flex-grow: 0;
  }
`;

export default function ({ theme }) {
  const { currentMessage, closeMessage } = useContext(NotificationContext);
  const handleClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      closeMessage();
    },
    [closeMessage],
  );
  return (
    <Wrapper
      open={!!currentMessage}
      autoHideDuration={3000}
      transitionDuration={1500}
      onClose={handleClose}
      key={currentMessage ? currentMessage.id : undefined}
    >
      <Content message={currentMessage ? currentMessage.message : null} />
    </Wrapper>
  );
}
