import React from "react";
import IconButton from "./IconButton";
import theme from "../../../theme";

import useLocalVideoToggle from "../../../hooks/useLocalVideoToggle";
import enabledIcon from "../../../assets/active-camera-icon.svg";
import disabledIcon from "../../../assets/inactive-camera-icon.svg";

export default function VideoToggleButton() {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  return (
    <IconButton
      onClick={toggleVideoEnabled}
      id="toggleVideo"
      background={!isVideoEnabled ? theme.colors.red : undefined}
      tooltip={isVideoEnabled ? "Disable Video" : "Enable Video"}
    >
      <img
        src={isVideoEnabled ? enabledIcon : disabledIcon}
        style={{ width: "24px" }}
        alt={isVideoEnabled ? "Disable Video" : "Enable Video"}
      />
    </IconButton>
  );
}
