import { useState, useEffect } from "react";
import { useWebsocketContext } from "../components/WebsocketProvider";

export default function useSessionReady() {
  const [token, setToken] = useState("");
  const { lastJsonMessage, setSessionStartTime } = useWebsocketContext();
  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }
    // session ready message recvd
    if (lastJsonMessage.message === "sessionReady") {
      setToken(lastJsonMessage.payload.token);
      setSessionStartTime(new Date());
    }

    console.log(lastJsonMessage);
  }, [lastJsonMessage, setSessionStartTime]);

  return { token, setToken };
}
