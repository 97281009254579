import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 3px;
`;
const Body = styled.div``;
const Footer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border};
`;
export default function Card({ children, footer }) {
  return (
    <Wrapper>
      <Body>{children}</Body>
      <Footer>{footer}</Footer>
    </Wrapper>
  );
}
