import styled from "styled-components";

const H2 = styled.h2`
  font-size: 21px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  margin: 0;
`;

const H3 = styled.h3`
  font-size: 21px;
  font-family: ${({ theme }) => theme.fonts.semiBold};
  font-weight: normal;
  margin: 0;
`;

export { H2, H3 };
