import { useCallback, useEffect, useState } from "react";
import { useWebsocketContext } from "../components/WebsocketProvider";

export default function useWaitingParticipant() {
  const [waitingParticipants, setWaitingParticipants] = useState([]);
  const { lastJsonMessage } = useWebsocketContext();

  const getQueueLength = useCallback(() => waitingParticipants.length, [
    waitingParticipants,
  ]);

  useEffect(() => {
    if (lastJsonMessage === null) {
      return;
    }

    const toUnix = t => new Date(t).getTime() / 1000;
    const filter = a =>
      a
        .filter(user => user.status === "waiting")
        .sort((a, b) => toUnix(a.connectedAt) - toUnix(b.connectedAt));

    if (lastJsonMessage.message === "participantList") {
      setWaitingParticipants(filter(lastJsonMessage.payload));
    }
  }, [lastJsonMessage]);

  return { waitingParticipants, getQueueLength };
}
