import { css } from "styled-components";

export default {
  blue: css`
    background: ${({ theme }) => theme.colors.primaryBlue};
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
  `,
  light: css`
    background: ${({ theme }) => theme.colors.light};
    color: ${({ theme }) => theme.colors.default};
  `,
};
