import React from "react";
import styled from "styled-components";
import Card from "../Card";
import ClientPicture from "./ClientPicture";
import ListItemWrapper from "./ListItemWrapper";

const PictureWrap = styled.div`
  height: 175px;
`;

const NamePlacard = styled.div`
  padding: 10px 10px 20px 10px;
  font-family: ${({ theme }) => theme.fonts.primary};
  line-height: 15px;
`;

export default function ClientItem({ selected, name, photo, onClick, index }) {
  const renderClientFooter = () => <NamePlacard>{name}</NamePlacard>;
  return (
    <ListItemWrapper name="selectClientCard">
      <Card footer={renderClientFooter()}>
        <PictureWrap>
          <ClientPicture
            onClick={onClick}
            selected={selected}
            photo={photo}
            index={index}
          />
        </PictureWrap>
      </Card>
    </ListItemWrapper>
  );
}
