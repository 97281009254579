import { useState, useRef, useEffect } from "react";

export default function useHoverOrTouch() {
  const [active, setActive] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    const handleMouseOver = () => setActive(true);
    const handleMouseOut = () => setActive(false);
    const handleTouchOn = () => setActive(true);
    const handleTouchOff = e => {
      if (e.target !== element) {
        setActive(false);
      }
    };

    if (element) {
      element.addEventListener("mouseover", handleMouseOver);
      element.addEventListener("mouseout", handleMouseOut);
      element.addEventListener("touchstart", handleTouchOn);
      window.addEventListener("touchstart", handleTouchOff);

      return () => {
        element.removeEventListener("mouseover", handleMouseOver);
        element.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, []);

  return [ref, active];
}
