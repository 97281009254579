import { useEffect, useState } from "react";

export function useDevices() {
  const [devices, setDevices] = useState([]);

  useEffect(() => {
    const getDevices = () =>
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices => setDevices(devices));

    getDevices();

    navigator.mediaDevices.addEventListener("devicechange", getDevices);
    return () =>
      navigator.mediaDevices.removeEventListener("devicechange", getDevices);
  }, []);

  return devices;
}

export function useAudioInputDevices() {
  const devices = useDevices();
  return devices.filter(device => device.kind === "audioinput");
}

export function useVideoInputDevices() {
  const devices = useDevices();
  return devices.filter(device => device.kind === "videoinput");
}

export function useAudioOutputDevices() {
  const devices = useDevices();
  return devices.filter(device => device.kind === "audiooutput");
}
