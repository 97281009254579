import React from "react";
import theme from "../../../theme";

import enabledIcon from "../../../assets/active-mic-icon.svg";
import disabledIcon from "../../../assets/inactive-mic-icon.svg";

import IconButton from "./IconButton";
import useLocalAudioToggle from "../../../hooks/useLocalAudioToggle";

export default function AudioToggleButton() {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  return (
    <IconButton
      onClick={toggleAudioEnabled}
      id="toggleAudio"
      background={!isAudioEnabled ? theme.colors.red : undefined}
      tooltip={isAudioEnabled ? "Disable Audio" : "Enable Audio"}
    >
      <img
        src={isAudioEnabled ? enabledIcon : disabledIcon}
        style={{ width: "20px" }}
        alt={isAudioEnabled ? "Disable Audio" : "Enable Audio"}
      />
    </IconButton>
  );
}
