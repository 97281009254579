import React, { useRef } from "react";
import styled from "styled-components";
import useCopy from "../../hooks/useCopy";
import Modal from "../Modal";
import LocalVideoPreview from "../LocalVideoPreview";
import {
  SelectVideoTrack,
  SelectAudioInputTrack,
  SelectAudioOutputTrack,
} from "../SessionControls/MediaSelection";
import {
  AudioToggleButton,
  VideoToggleButton,
} from "../SessionControls/Buttons";
import Footer from "./Footer";

const Wrapper = styled.div`
  text-align: left;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;

const VideoPreviewWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  height: 40px;
  width: 40px;
  padding: 0 10px;
`;

const MediaControls = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 50px;
  text-align: center;
`;

const IntroText = styled.div`
  text-align: left;
  margin-bottom: 15px;
  padding: 15px 0;
`;

const SelectWrapper = styled.div`
  margin-bottom: 10px;
`;
const Selections = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    padding-left: 20px;
    width: 50%;
  }
`;
const Item = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 50%;
  }
`;
function MediaSelection() {
  const videoRef = useRef(null);
  const [introText] = useCopy(["dashbaord.precheck.introText"]);

  return (
    <div>
      <IntroText>{introText}</IntroText>
      <Wrapper>
        <Item>
          <VideoPreviewWrapper>
            <LocalVideoPreview videoRef={videoRef} />
            <MediaControls>
              <ButtonWrapper>
                <VideoToggleButton />
              </ButtonWrapper>
              <ButtonWrapper>
                <AudioToggleButton />
              </ButtonWrapper>
            </MediaControls>
          </VideoPreviewWrapper>
        </Item>
        <Selections>
          <SelectWrapper>
            <SelectVideoTrack />
          </SelectWrapper>
          <SelectWrapper>
            <SelectAudioInputTrack />
          </SelectWrapper>
          <SelectWrapper>
            <SelectAudioOutputTrack />
          </SelectWrapper>
        </Selections>
      </Wrapper>
    </div>
  );
}
export default function SelectMediaModal({ close, onJoin }) {
  return (
    <Modal
      footer={Footer({ close, onJoin })}
      close={close}
      title="Pre-Session Check"
    >
      <MediaSelection />
    </Modal>
  );
}
