import Video from "twilio-video";
import { useCallback, useEffect, useRef, useState } from "react";
import { ensureMediaPermissions } from "../../../utils";

export function useLocalAudioTrack() {
  const [track, setTrack] = useState(undefined);

  const getLocalAudioTrack = useCallback(({ deviceId = "" } = {}) => {
    const device = deviceId ? { deviceId: { exact: deviceId } } : {};
    const options = {
      ...device,
    };

    return ensureMediaPermissions().then(() =>
      Video.createLocalAudioTrack(options).then(newTrack => {
        setTrack(newTrack);
        return newTrack;
      }),
    );
  }, []);

  useEffect(() => {
    getLocalAudioTrack();
  }, [getLocalAudioTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on("stopped", handleStopped);
      return () => {
        track.off("stopped", handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalAudioTrack];
}

export function useLocalVideoTrack() {
  const [track, setTrack] = useState(undefined);
  const [sinkId, setSinkId] = useState("");
  const localDeviceId = useRef("");

  const getLocalVideoTrack = useCallback((allOptions = {}) => {
    const { publish = true, ...newOptions } = allOptions;

    if (newOptions.deviceId) {
      localDeviceId.current = newOptions.deviceId.exact;
      setSinkId(newOptions.deviceId.exact);
    }

    const device = localDeviceId.current
      ? {
          deviceId: {
            exact: localDeviceId.current,
          },
        }
      : {};
    const options = {
      video: {
        width: 1280,
        height: 720,
        frameRate: 24,
      },
      name: `camera-${Date.now()}`,
      ...device,
      ...newOptions,
    };

    return ensureMediaPermissions().then(() =>
      Video.createLocalVideoTrack(options).then(newTrack => {
        if (publish) {
          setTrack(newTrack);
        }
        return newTrack;
      }),
    );
  }, []);

  useEffect(() => {
    getLocalVideoTrack();
  }, [getLocalVideoTrack]);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on("stopped", handleStopped);
      return () => {
        track.off("stopped", handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalVideoTrack, sinkId];
}

export default function useLocalTracks() {
  const [audioTrack, getLocalAudioTrack] = useLocalAudioTrack();
  const [videoTrack, getLocalVideoTrack, sinkId] = useLocalVideoTrack();

  const localTracks = [audioTrack, videoTrack].filter(
    track => track !== undefined,
  );

  return {
    localTracks,
    getLocalAudioTrack,
    getLocalVideoTrack,
    currentVideoDevice: sinkId,
  };
}
