import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  cursor: pointer;
`;
export default function ({ close, fill = "#ffffff", height, width }) {
  return (
    <Wrapper onClick={close}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5073 1.8208L15.6865 0L8.75781 6.92871L1.8291 0L0.00830078 1.8208L6.93701 8.74951L0.00830078 15.6782L1.8291 17.499L8.75781 10.5703L15.6865 17.499L17.5073 15.6782L10.5786 8.74951L17.5073 1.8208Z"
          fill={fill}
        />
      </svg>
    </Wrapper>
  );
}
