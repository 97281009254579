import React from "react";
import styled from "styled-components";
import Header from "../components/Header";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 50px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const MessageWrapper = styled.div`
  padding: 0 10px;

  h3 {
    margin: 0;
  }
`;

function RoomDisabledView() {
  return (
    <div>
      <Header />
      <Body>
        <Wrapper>
          <MessageWrapper>
            <h3>Room not enabled</h3>
          </MessageWrapper>
        </Wrapper>
      </Body>
    </div>
  );
}

export default RoomDisabledView;
