import React, { createContext } from "react";
import { SelectedParticipantProvider } from "./useSelectedParticipant/useSelectedParticipant";

import AttachVisibilityHandler from "./AttachVisibilityHandler/AttachVisibilityHandler";
import useHandleRoomDisconnectionErrors from "./useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors";
import useHandleOnDisconnect from "./useHandleOnDisconnect/useHandleOnDisconnect";
import useHandleTrackPublicationFailed from "./useHandleTrackPublicationFailed/useHandleTrackPublicationFailed";
import useLocalTracks from "./useLocalTracks/useLocalTracks";
import useRoom from "./useRoom/useRoom";
import useAudioSink from "./useAudioSink";

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export const VideoContext = createContext(null);

export function VideoProvider({
  options,
  children,
  onError = () => {},
  onDisconnect = () => {},
}) {
  const onErrorCallback = error => {
    console.log(`ERROR: ${error.message}`, error);
    onError(error);
  };

  const {
    localTracks,
    getLocalAudioTrack,
    getLocalVideoTrack,
    currentVideoDevice,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(
    localTracks,
    onErrorCallback,
    options,
  );

  const { activeSinkId, setActiveSinkId } = useAudioSink();

  // Register onError and onDisconnect callback functions.
  useHandleRoomDisconnectionErrors(room, onError);
  useHandleTrackPublicationFailed(room, onError);
  useHandleOnDisconnect(room, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        activeSinkId,
        setActiveSinkId,
        getLocalVideoTrack,
        getLocalAudioTrack,
        currentVideoDevice,
        connect,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
