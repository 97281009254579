import React, { createContext, useReducer, useCallback } from "react";

export const NotificationContext = createContext({});

const ADD_MESSAGE = "ADD_MESSAGE";
const MESSAGE_CLOSED = "MESSAGE_CLOSED";

const addMessage = (state, action) => {
  const messageIndex = state.messageIndex + 1;
  const newMessage = { message: action.message, id: messageIndex };
  const unreadMessages = state.unreadMessages.concat(newMessage);
  const newState = {
    ...state,
    messageIndex,
    unreadMessages,
  };
  // Advance to first message if no current message
  if (state.currentMessage) {
    return newState;
  }
  return nextMessage(newState);
};

const nextMessage = state => {
  return {
    ...state,
    currentMessage: state.unreadMessages[0],
    unreadMessages: state.unreadMessages.slice(1),
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return addMessage(state, action);
    case MESSAGE_CLOSED:
      return nextMessage(state, action);
    default:
      return state;
  }
};

const initialState = {
  messageIndex: 0,
  currentMessage: null,
  unreadMessages: [],
};

export default function ({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { currentMessage } = state;

  const addMessage = useCallback(
    message => {
      dispatch({ type: ADD_MESSAGE, message });
    },
    [dispatch],
  );

  const closeMessage = useCallback(
    message => {
      dispatch({ type: MESSAGE_CLOSED, message });
    },
    [dispatch],
  );

  return (
    <NotificationContext.Provider
      value={{ currentMessage, addMessage, closeMessage }}
    >
      {children}
    </NotificationContext.Provider>
  );
}
