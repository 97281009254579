import React from "react";
import styled from "styled-components";

import { MonthDropdown, DayDropdown, TimeInput } from "./Inputs";
import Label from "../Elements/Label";
import Flex from "../Elements/Flex";

const Wrapper = styled.div`
  min-width: 320px;
`;

const DayAndTimeDropdown = ({
  disabled,
  date = new Date(),
  label,
  onChange,
}) => {
  const now = new Date();
  const month = date.getMonth();
  const day = date.getDate();
  const time = `${date.getHours()}:${date.getMinutes()}`;

  const handleChange = (m, d, t) => {
    if (t === "") {
      t = time;
    }

    const year = m < now.getMonth() ? now.getFullYear() + 1 : now.getFullYear();
    const parts = t.split(":");
    return onChange(new Date(year, m, d, parts[0], parts[1]));
  };

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Flex basis={["45%", "25%", "30%"]}>
        <MonthDropdown
          disabled={disabled}
          month={month}
          onChange={month => handleChange(month, day, time)}
        />
        <DayDropdown
          disabled={disabled}
          month={month}
          day={day}
          onChange={day => handleChange(month, day, time)}
        />
        <TimeInput
          disabled={disabled}
          value={time}
          onChange={time => handleChange(month, day, time)}
        />
      </Flex>
    </Wrapper>
  );
};

export default DayAndTimeDropdown;
