import { useEffect } from "react";
import useRoomState from "./useRoomState";
import { useWebsocketContext } from "../components/WebsocketProvider";

export default function useJoinedSession() {
  const roomState = useRoomState();
  const { sendJsonMessage } = useWebsocketContext();

  useEffect(() => {
    if (roomState === "connected") {
      sendJsonMessage({
        message: "joinedSession",
      });
    }
  }, [roomState, sendJsonMessage]);
}
