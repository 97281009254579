import React from "react";
import ReactDOM from "react-dom";
import Router from "./router";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "styled-components";
import { Normalize } from "styled-normalize";
import ourTheme from "./theme";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={ourTheme}>
      <Normalize />
      <Router />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
