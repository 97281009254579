import React from "react";
import styled from "styled-components";

const Image = styled.img`
  display: block;
  width: 51px;
  height: auto;

  @media (min-width: 768px) {
    width: 82px;
  }
`;

function ProfileImage({ src, alt = "" }) {
  return <Image src={src} alt={alt} />;
}

export default ProfileImage;
