import React, { createContext, useContext, useMemo, useState } from "react";

import useWebSocket from "react-use-websocket";
import { useUserContext } from "../UserProvider";
import actions from "../../actions";

// the context
export const WebsocketContext = createContext(null);

// the provider
export function WebsocketProvider({ children }) {
  const { user } = useUserContext();
  const [sessionStartTime, setSessionStartTime] = useState(null);

  const opts = useMemo(
    () => ({
      share: true,
      onClose: e => console.log(e),
      filter: event => {
        const parsed = JSON.parse(event.data);
        return parsed.message !== actions.PONG;
      },
      shouldReconnect: () => true,
    }),
    [],
  );

  const { readyState, lastJsonMessage, sendJsonMessage } = useWebSocket(
    process.env.REACT_APP_WS_URL,
    opts,
    user.authorized,
  );

  return (
    <WebsocketContext.Provider
      value={{
        readyState,
        sendJsonMessage,
        lastJsonMessage,
        sessionStartTime,
        setSessionStartTime,
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}

// get the context;
export function useWebsocketContext() {
  const context = useContext(WebsocketContext);
  if (!context) {
    throw new Error(
      "useWebsocketContext must be used within a WebsocketProvider",
    );
  }

  return context;
}
