import React from "react";
import styled, { createGlobalStyle } from "styled-components";

import connectionOptions from "./connectionOptions";
import { VideoProvider } from "./components/VideoProvider";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

const AppContainer = styled.div`
  display: block;
  color: ${({ theme }) => theme.colors.default};
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.primary};
  overflow: hidden;
`;

function App({ children }) {
  return (
    <AppContainer>
      <GlobalStyle />
      <VideoProvider
        options={connectionOptions}
        onError={e => {
          if (e.code === 53118) {
            console.log("room completed! that wasn't a fucking error!");
            return;
          }
          throw e;
        }}
      >
        {children}
      </VideoProvider>
    </AppContainer>
  );
}

export default App;
