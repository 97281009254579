import React from "react";
import styled from "styled-components";
import waitingRoomIcon from "../../assets/waiting-room-icon.svg";
import { H2 } from "../Elements/Headings";
import ConnectionStatus from "../ConnectionStatus";

const Header = styled.div`
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
`;

export default function () {
  return (
    <Header>
      <TitleWrapper>
        <img style={{ marginRight: "10px" }} src={waitingRoomIcon} alt="" />
        <H2>Your Waiting Room</H2>
      </TitleWrapper>
      <ConnectionStatus />
    </Header>
  );
}
