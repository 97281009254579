import React from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

const StyledIconButton = styled.button`
  display: block;
  width: 100%;
  padding: 100% 0 0;
  border-radius: 50%;
  background: ${props => props.background || "white"};
  color: white;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-sizing: content-box;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0 0 4px rgba(44, 45, 48, 0.4),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:active {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 0 4px rgba(44, 45, 48, 0.6), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
  }
`;

const IconButton = ({ tooltip, ...rest }) => {
  if (!tooltip) {
    return <StyledIconButton {...rest} />;
  }

  return (
    <Tooltip title={tooltip}>
      <StyledIconButton {...rest} />
    </Tooltip>
  );
};

export default IconButton;
