import React from "react";
import styled from "styled-components";
import Participant from "../Participant/";
import useParticipants from "../../hooks/useParticipants";
import useVideoContext from "../../hooks/useVideoContext";

const Container = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (min-width: 768px) {
    flex-direction: column;
    padding: 10px;
    background-color: white;
  }
`;

const ParticipantListTitle = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    font-family: ${({ theme }) => theme.fonts.semiBold};
    font-weight: normal;
    font-size: 16px;
  }
`;

export default function ParticipantList({ isAdmin }) {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();

  return (
    <Container>
      <ParticipantListTitle>In Session</ParticipantListTitle>
      <Participant participant={localParticipant} showAdminControls={false} />
      {participants.map(participant => (
        <Participant
          key={participant.sid}
          participant={participant}
          showAdminControls={isAdmin}
        />
      ))}
    </Container>
  );
}
