import { useState, useEffect, useContext } from "react";
import useWaitingParticipants from "../../../hooks/useWaitingParticipants";
import { NotificationContext } from "../../Notifications";

export default function (isAdmin) {
  const { waitingParticipants } = useWaitingParticipants();
  const { addMessage } = useContext(NotificationContext);
  const [seenParticipants, setSeenParticipants] = useState([]);

  useEffect(() => {
    if (!isAdmin) {
      return;
    }
    const newUnseenParticipants = waitingParticipants.filter(
      p => !seenParticipants.map(s => s.identity).includes(p.identity),
    );
    newUnseenParticipants.forEach(p =>
      addMessage(`${p.displayName} is in your waiting room`),
    );
    if (newUnseenParticipants.length > 0) {
      setSeenParticipants(prev => prev.concat(newUnseenParticipants));
    }
  }, [isAdmin, addMessage, waitingParticipants, seenParticipants]);
}
