import React, { useState } from "react";
import styled, { css } from "styled-components";

const Headers = styled.div`
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  margin-bottom: 20px;
  > div {
    margin-right: 10px;
  }
`;
const HeaderItem = styled.div`
  margin-right: 10px;
  cursor: pointer;
  letter-spacing: 0.25px;
  padding-bottom: 3px;
  ${({ active }) =>
    active &&
    css`
      border-bottom: 3px solid ${({ theme }) => theme.colors.primaryBlue};
    `}
`;

const Body = styled.div`
  background: white;
`;

const Tabs = ({ panels }) => {
  const [activePanel, setActivePanel] = useState(0);
  return (
    <div>
      <Headers>
        {panels.map((panel, i) => (
          <HeaderItem
            active={i === activePanel}
            onClick={() => setActivePanel(i)}
            key={`tabHeader${i}`}
          >
            {panel[0]}
          </HeaderItem>
        ))}
      </Headers>
      <Body>{panels[activePanel][1]}</Body>
    </div>
  );
};

export default Tabs;
