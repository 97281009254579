import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { requestAddParticipants } from "../../httpapi";
import ClientPicture from "../ClientList/ClientPicture";
import ConfirmationModal from "../ConfirmationModal";
import Button from "../Elements/Button";
import waitingRoomEmpty from "../../assets/waiting-room-empty.svg";

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 90px;
  left: 20px;
  width: 190px;
  max-height: 400px;
  background: white;
  box-shadow: 0px 0px 3px rgba(44, 45, 48, 0.4);
  border-radius: 3px;
`;

const Header = styled.div`
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  padding: 10px;
  font-weight: 600;
`;

const Body = styled.div`
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
`;

const Client = styled.div`
  padding-bottom: 10px;
  &:last-of-type {
    padding-bottom: 0;
  }
`;

const PictureWrap = styled.div`
  position: relative;
  padding-top: 56%;
`;

const PictureRatio = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const Name = styled.div`
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: left;
  font-weight: normal;
  padding-top: 5px;
`;

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  text-align: center;
  padding: 10px;
`;

const EmptyImage = styled.img`
  display: block;
  margin: 0 auto;
  width: 90%;
`;

const EmptyCaption = styled.p`
  font-size: 15px;
  font-family: ${({ theme }) => theme.fonts.primary};
  margin: 0;
  text-align: left;
  display: block;
  font-weight: normal;
`;

export default function WaitingRoom({ participants, onClose }) {
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const modalWrapper = useRef(null);

  useEffect(() => {
    // remove disconnected participants from selectedParticipants state
    const activeSelected = selectedParticipants.filter(p =>
      participants.find(pt => pt.identity === p.identity),
    );
    if (activeSelected.length !== selectedParticipants.length) {
      setSelectedParticipants(activeSelected);
    }
  }, [participants, selectedParticipants, setSelectedParticipants]);

  const addToSession = async () => {
    const success = await requestAddParticipants(
      selectedParticipants.map(p => p.identity),
    );
    if (success) {
      console.log("success");
      setConfirmationOpen(false);
      return;
    }
    throw new Error("something went wrong");
  };

  const toggleSelectedParticipant = participant => {
    setSelectedParticipants(
      selectedParticipants.find(p => p.identity === participant.identity)
        ? selectedParticipants.filter(p => p.identity !== participant.identity)
        : [participant, ...selectedParticipants],
    );
  };

  const getNames = () => {
    const names = selectedParticipants.map(p => p.displayName);
    return names.length < 3
      ? names.join(" and ")
      : names.slice(0, -1).join(", ") + ", and " + names.slice(-1);
  };
  useEffect(() => {
    const clickOffClose = e => {
      if (
        modalWrapper.current !== e.target &&
        !modalWrapper.current.contains(e.target)
      ) {
        onClose();
      }
    };
    window.addEventListener("click", clickOffClose);
    return () => {
      window.removeEventListener("click", clickOffClose);
    };
  }, [onClose]);

  return (
    <div ref={modalWrapper}>
      <Container>
        <Header>Waiting Room</Header>
        <Body>
          {participants.length ? (
            participants.map((p, i) => (
              <Client key={p.identity}>
                <PictureWrap>
                  <PictureRatio>
                    <ClientPicture
                      selected={
                        typeof selectedParticipants.find(
                          pt => pt.identity === p.identity,
                        ) !== "undefined"
                      }
                      photo={p.photoUrl}
                      onClick={() => toggleSelectedParticipant(p)}
                      index={i}
                    />
                  </PictureRatio>
                </PictureWrap>
                <Name>{p.displayName}</Name>
              </Client>
            ))
          ) : (
            <EmptyImage src={waitingRoomEmpty} />
          )}
        </Body>
        <Footer>
          {participants.length ? (
            <Button
              disabled={selectedParticipants.length === 0}
              onClick={() => setConfirmationOpen(true)}
              id="addToSession"
              full
            >
              Add to Session
            </Button>
          ) : (
            <EmptyCaption>Your waiting room is empty.</EmptyCaption>
          )}
        </Footer>
      </Container>
      {confirmationOpen ? (
        <ConfirmationModal
          title="Add to Session"
          onConfirm={() => {
            onClose();
            addToSession();
          }}
          onClose={() => {
            setConfirmationOpen(false);
          }}
        >
          You have added {getNames()} to your session. Please confirm or cancel
          this action.
        </ConfirmationModal>
      ) : null}
    </div>
  );
}
