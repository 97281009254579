import React from "react";
import Select from "../Elements/Select";
import TextInput from "../Elements/TextInput";

const months = [
  "January",
  "Feburary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const daysInMonth = (month, year) => {
  return new Date(year, month + 1, 0).getDate();
};

export const MonthDropdown = ({ onChange, month, ...rest }) => {
  const options = months.map((v, i) => ({ value: i, text: v }));

  return (
    <Select
      {...rest}
      id="monthSelection"
      onChange={e => onChange(parseInt(e.target.value))}
      value={month}
      options={options}
    />
  );
};

export const DayDropdown = ({ month, day, onChange, ...rest }) => {
  const now = new Date();
  const currentMonth = parseInt(now.getMonth());
  const dayOffset = month === currentMonth ? now.getDate() : 1;
  const options = Array.from(
    new Array(daysInMonth(month, now.getFullYear()) + 1 - dayOffset),
  )
    .map((_, d) => d + dayOffset)
    .map(i => ({ value: i, text: i }));
  return (
    <Select
      {...rest}
      id="monthSelection"
      onChange={e => onChange(parseInt(e.target.value))}
      value={day}
      options={options}
    />
  );
};

export const TimeInput = ({ value, onChange, ...rest }) => {
  return (
    <TextInput
      {...rest}
      value={value}
      id="timeInput"
      onChange={e => onChange(e.target.value)}
      type="time"
      required
    />
  );
};
