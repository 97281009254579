import StartSessionButton from "../StartSessionButton";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > :first-child {
    margin-bottom: 15px;
  }
  > * {
    width: 100%;
  }

  @media (min-width: 768px) {
    flex-wrap: nowrap;
    > :first-child {
      width: 50%;
      margin-bottom: 0;
    }
    > * {
      width: auto;
    }
  }
`;

export default function ({ isEmpty, enabled, onClick }) {
  return !isEmpty ? (
    <Wrapper>
      <div>
        You have clients in your waiting room. Select client(s) to start your
        session.
      </div>
      <div>
        <StartSessionButton enabled={enabled} onClick={onClick} />
      </div>
    </Wrapper>
  ) : (
    <div>
      When your clients have signed into their session, they will appear below
      in the waiting room.
    </div>
  );
}
