import React from "react";
import Button from "../Elements/Button";

function StartSessionButton({ enabled, onClick }) {
  return (
    <Button id="startSession" full disabled={!enabled} onClick={onClick}>
      Start This Session
    </Button>
  );
}

export default StartSessionButton;
