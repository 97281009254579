import React from "react";
import styled from "styled-components";

const Dot = styled.div`
  background: ${({ theme }) => theme.colors.default};
  width: 6px;
  height: 6px;
  border-radius: 50%;
`;

const Dots = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > * {
    margin: 5px 0;
  }
`;

const Wrapper = styled.div`
  width: 45px;
  height: auto;
  position: relative;
  overflow: hidden;
  background: white;
  border-left: 1px solid ${({ theme }) => theme.colors.border};
  &:hover {
    background: ${({ theme }) => theme.colors.light};
    cursor: pointer;
  }
`;

export default function OverflowMenu(props) {
  return (
    <Wrapper {...props}>
      <Dots>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
      </Dots>
    </Wrapper>
  );
}
