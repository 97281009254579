import React from "react";
import Link from "../Elements/Link";

import { useUserContext } from "../UserProvider";

const CopyRoomUrl = ({ text }) => {
  const { getRoomUrl } = useUserContext();
  const copyURL = () => {
    const el = document.createElement("textarea");
    el.value = getRoomUrl();
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  };
  return <Link onClick={copyURL}>{text}</Link>;
};

export default CopyRoomUrl;
