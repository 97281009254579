import React from "react";
import Heartbeat from "react-heartbeat";
import { ReadyState } from "react-use-websocket";
import { useWebsocketContext } from "../WebsocketProvider";

export default function WebsocketHeartbeat() {
  const { readyState, sendJsonMessage } = useWebsocketContext();

  const heartbeatInterval = 20000;

  const heartbeatFunction = () => {
    sendJsonMessage({
      message: "ping",
    });
  };

  if (readyState !== ReadyState.OPEN) {
    return null;
  }

  return (
    <Heartbeat
      heartbeatFunction={heartbeatFunction}
      heartbeatInterval={heartbeatInterval}
    />
  );
}
