import React from "react";
import IconButton from "./IconButton";
import theme from "../../../theme";

import icon from "../../../assets/end-call-icon.svg";

export default function ExitButton({ onClick }) {
  return (
    <IconButton
      onClick={onClick}
      background={theme.colors.red}
      id="endSession"
      tooltip="Close Session"
    >
      <img src={icon} alt="Close Session" />
    </IconButton>
  );
}
