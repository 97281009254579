import styled from "styled-components";
const TextArea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  width: 100%;
  padding: 9px 13px;
  box-sizing: border-box;
  appearance: none;
  min-height: 100px;
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 16px;
  line-height: 1.3;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.light : theme.colors.white};
`;

export default TextArea;
