import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { ReadyState } from "react-use-websocket";
import { useParams, useHistory } from "react-router-dom";

import LocalVideoPreview from "../components/LocalVideoPreview/LocalVideoPreview";
import Header from "../components/Header";
import PhotoCanvas from "../components/PhotoCanvas";
import Footer from "../components/Footer";
import { CheckInView, CheckedInView } from "../components/ClientCheckin";
import { useWebsocketContext } from "../components/WebsocketProvider";
import useSessionReady from "../hooks/useSessionReady";
import { useWaitingRoomContext } from "../components/WaitingRoomProvider";
import Anchor from "../components/Elements/Anchor";
import Session from "../pages/SessionView";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 100px 50px;
  }
`;

const Item = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    margin: 0 10px;
    flex: 1 1 ${({ basis }) => basis};
    max-width: 700px;
  }
`;

function ClientRoomView() {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const bodyRef = useRef(null);
  const history = useHistory();
  const { roomKey } = useParams();
  const { readyState } = useWebsocketContext();
  const { token } = useSessionReady();
  const { room } = useWaitingRoomContext();

  // render the checkin area when the socket is open.
  const renderCheckinArea = () => {
    return readyState !== ReadyState.OPEN ? (
      <CheckInView videoRef={videoRef} canvasRef={canvasRef} />
    ) : (
      <CheckedInView />
    );
  };

  const onDisconnect = useCallback(() => {
    history.push(`/room/${roomKey}/complete`);
  }, [history, roomKey]);

  if (token !== "") {
    return (
      <Session token={token} isAdmin={false} onDisconnect={onDisconnect} />
    );
  } else {
    return (
      <>
        <Header />
        <Body ref={bodyRef}>
          <Item basis="44%">{renderCheckinArea()}</Item>
          <Item basis="56%">
            <LocalVideoPreview videoRef={videoRef} />
            <PhotoCanvas canvasRef={canvasRef} />
          </Item>
        </Body>
        <Footer bodyRef={bodyRef}>
          <div>
            Are you {room.therapistDisplayName}?{" "}
            <Anchor href={process.env.REACT_APP_MEMBER_WEB}>Login Here</Anchor>
          </div>
        </Footer>
      </>
    );
  }
}
export default ClientRoomView;
