import React from "react";
import styled from "styled-components";
import logo from "../../assets/pt-sessions-logo-lg.svg";
import { useUserContext } from "../UserProvider";

const Wrapper = styled.header`
  background: ${({ theme }) => theme.colors.primaryBlue};
  width: 100%;
  padding: 11.5px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 768px) {
    padding: 17.5px 15px;
  }
`;

const HeaderLogo = styled.img`
  height: 27px;
  display: block;
  user-select: none;
  @media (min-width: 768px) {
    height: 35px;
  }
`;

const AccountLink = styled.a`
  font-size: 0.9em;
  display: block;
  color: ${({ theme }) => theme.colors.light};
  padding-right: 30px;
  text-decoration: none;
`;

function Header({ isTherapist = false }) {
  const { user } = useUserContext();
  return (
    <Wrapper>
      <HeaderLogo src={logo} />
      {isTherapist && (
        <AccountLink
          href={`https://member.psychologytoday.com/${user.countryCode.toLowerCase()}/home`}
        >
          Back to Account
        </AccountLink>
      )}
    </Wrapper>
  );
}

export default Header;
