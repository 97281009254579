import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import { checkElementOverlap } from "../../utils";

const Wrapper = styled.footer`
  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
    `};
  background: ${({ theme }) => theme.colors.light};
  z-index: 8;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
`;

export default function Footer({ bodyRef, children }) {
  const footerRef = useRef(null);
  const [fixed, setFixed] = useState(true);

  useEffect(() => {
    const body = bodyRef && bodyRef.current;
    const footer = footerRef.current;
    if (!body || !window.ResizeObserver) return;
    const checkFooterOverlap = () => {
      setFixed(true);
      setFixed(!checkElementOverlap(body, footer));
    };
    const resizeObserver = new window.ResizeObserver(checkFooterOverlap);
    resizeObserver.observe(footer);
    return () => {
      resizeObserver.unobserve(footer);
    };
  }, [setFixed, bodyRef]);

  return (
    <Wrapper ref={footerRef} fixed={fixed}>
      {children}
    </Wrapper>
  );
}
