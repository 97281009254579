import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import App from "./App";
import ClientRoomView from "./pages/ClientRoomView";
import ClientExitRoomView from "./pages/ClientExitRoomView";
import RoomDisabledView from "./pages/RoomDisabledView";
import TherapistRoomView from "./pages/TherapistRoomView";
import { WebsocketProvider } from "./components/WebsocketProvider";
import { UserProvider } from "./components/UserProvider";
import { WaitingRoomProvider } from "./components/WaitingRoomProvider";
import Authenticated from "./components/Authenticated";
import WaitingRoomDetails from "./components/WaitingRoomDetails";
import WebsocketHeartbeat from "./components/WebsocketHeartbeat";

function AppRouter() {
  return (
    <Router>
      <App>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="/dashboard">
            <UserProvider type="therapist">
              <Authenticated>
                <WebsocketProvider>
                  <TherapistRoomView />
                  <WebsocketHeartbeat />
                </WebsocketProvider>
              </Authenticated>
            </UserProvider>
          </Route>
          <Route exact path="/room/:roomKey">
            <WaitingRoomProvider>
              <WaitingRoomDetails>
                <UserProvider type="client">
                  <WebsocketProvider>
                    <ClientRoomView />
                    <WebsocketHeartbeat />
                  </WebsocketProvider>
                </UserProvider>
              </WaitingRoomDetails>
            </WaitingRoomProvider>
          </Route>
          <Route exact path="/room/:roomKey/complete">
            <WaitingRoomProvider>
              <WaitingRoomDetails>
                <ClientExitRoomView />
              </WaitingRoomDetails>
            </WaitingRoomProvider>
          </Route>
          <Route exact path="/room/:roomKey/disabled">
            <RoomDisabledView />
          </Route>
        </Switch>
      </App>
    </Router>
  );
}

export default AppRouter;
