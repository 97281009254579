import React, { useState, useEffect } from "react";
import waitingRoomIcon from "../../assets/waiting-room.svg";
import Panel from "../Panel";
import SelectMediaModal from "../SelectMediaModal";
import List from "./List";
import ClientItem from "./ClientItem";
import Header from "./Header";
import SubHeader from "./SubHeader";
import { requestStartSession } from "../../httpapi";
import useWaitingParticipant from "../../hooks/useWaitingParticipants";
import ListItemWrapper from "./ListItemWrapper";

function ClientList() {
  const [selected, setSelected] = useState([]);
  const [confirmStart, setConfirmStart] = useState(false);

  const { waitingParticipants, getQueueLength } = useWaitingParticipant();

  useEffect(() => {
    // remove disconnected participants from selected state
    const activeSelected = selected.filter(p =>
      waitingParticipants.find(pt => pt.identity === p.identity),
    );
    if (activeSelected.length !== selected.length) {
      setSelected(activeSelected);
    }
  }, [waitingParticipants, selected, setSelected]);

  const handleClick = (k, p) => e => {
    const current =
      typeof selected.find(pt => pt.identity === k) == "undefined"
        ? [...selected, p]
        : selected.filter(pt => pt.identity !== k);
    return setSelected(current);
  };

  const renderListElement = (p, i) => {
    const sel =
      typeof selected.find(pt => pt.identity === p.identity) !== "undefined";
    return (
      <ClientItem
        selected={sel}
        key={p.identity}
        index={i}
        name={p.displayName}
        photo={p.photoUrl}
        onClick={handleClick(p.identity, p)}
      />
    );
  };

  // helpers
  const enabled = selected.length > 0;
  const isEmpty = getQueueLength() === 0;
  const onClick = () => {
    if (requestStartSession(selected.map(u => u.identity))) {
      return;
    }
    throw new Error("something went wrong");
  };

  const renderPanelContent = () => {
    return isEmpty ? (
      <List justify="center">
        <img src={waitingRoomIcon} alt="Your waiting room is empty" />
      </List>
    ) : (
      <List justify="space-between" participants={getQueueLength()}>
        {waitingParticipants.map(renderListElement)}
        {getQueueLength() % 3 !== 0 && <ListItemWrapper />}
      </List>
    );
  };

  return (
    <>
      {confirmStart && (
        <SelectMediaModal
          onJoin={onClick}
          close={() => setConfirmStart(false)}
        />
      )}
      <Panel
        header={Header()}
        subheader={SubHeader({
          isEmpty: isEmpty,
          enabled: enabled,
          onClick: () => setConfirmStart(true),
        })}
      >
        {renderPanelContent()}
      </Panel>
    </>
  );
}

export default ClientList;
