import React from "react";
import styled from "styled-components";
import downArrow from "../../../assets/arrow-down.svg";
import Label from "../Label";

const Styled = styled.select`
  width: 100%;
  height: 50px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 3px;
  appearance: none;
  background: url("${downArrow}") 85% / 15% no-repeat #ffffff;
  background-size: 15px;
  padding-right: 30px;
  padding-left: 15px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.light : theme.colors.white};
`;

export default function Select({ label, options, value, ...rest }) {
  return (
    <>
      {label && <Label>{label}</Label>}
      <Styled value={value} {...rest}>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </Styled>
    </>
  );
}
