import { H2 } from "../Elements/Headings";
import React from "react";
import styled from "styled-components";
import { ReadyState } from "react-use-websocket";
import { useWebsocketContext } from "../WebsocketProvider";

const Wrapper = styled.div`
  padding-top: 50px;
  @media (min-width: 768px) {
    padding-top: 0;
  }
`;
const TextWrapper = styled.div`
  height: 39px;
  font-style: italic;
`;

function CheckedInView() {
  const { readyState } = useWebsocketContext();

  return readyState === ReadyState.OPEN ? (
    <Wrapper>
      <H2>Thanks!</H2>
      <TextWrapper>Hold tight, our session will begin shortly.</TextWrapper>
    </Wrapper>
  ) : null;
}

export default CheckedInView;
