import React from "react";
import styled from "styled-components";
import Checkbox from "../Elements/Checkbox";

const RelativeWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
`;

const CheckboxWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Picture = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.border};
  background-image: url("${({ src }) => src}");
  background-size: cover;
  background-position: center;
`;

export default function ClientPicture({ onClick, selected, photo, index }) {
  return (
    <RelativeWrapper>
      <CheckboxWrapper>
        <Checkbox
          checked={selected}
          onClick={onClick}
          id={"clientPictureCheckbox" + index}
        />
      </CheckboxWrapper>
      <Picture src={photo} />
    </RelativeWrapper>
  );
}
