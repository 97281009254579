import React from "react";
import Modal from "../Modal";
import styled from "styled-components";
import Button from "../Elements/Button";

const Body = styled.div`
  text-align: left;
  font-size: 14px;
  line-height: 1.5;

  p {
    margin: 40px 0;
  }

  p:first-of-type,
  p:last-of-type {
    margin: 0;
  }

  @media (min-width: 758px) {
    font-size: 17px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default function TOSModal({ close }) {
  const renderFooter = () => {
    return (
      <FooterWrapper>
        <Button onClick={close} variant="blue">
          Close
        </Button>
      </FooterWrapper>
    );
  };
  return (
    <Modal title="Terms of Service" close={close} footer={renderFooter()}>
      <Body>
        <p>
          Sit fuga quidem ducimus repudiandae modi Nemo optio officiis vero
          molestiae eius? Vero quos assumenda nostrum architecto ipsam sit?
          Saepe architecto ducimus illum nihil debitis Rem tempora itaque non
          deserunt.
        </p>
        <p>
          Lorem eligendi doloribus odit quo iure? Alias placeat eos doloremque
          repellendus maiores officia Minima sequi recusandae ab saepe facilis
          Ex aliquam quam perferendis magni neque Tempore nihil quam sapiente
          vero!
        </p>
        <p>
          Elit animi praesentium minima facere voluptate illum. Molestias iusto
          voluptate odio fuga ipsa Nesciunt harum eos id ut consectetur eum
          Mollitia repellat laborum tenetur odit itaque! Assumenda cumque vitae
          odit.
        </p>
        <p>
          Consectetur laborum omnis nihil minus incidunt inventore, repudiandae
          veritatis excepturi. Culpa vel ipsum nihil minus praesentium. Facilis
          est accusantium sunt ullam temporibus? Molestiae eligendi blanditiis
          provident deleniti quia, numquam fugiat.
        </p>
        <p>
          Adipisicing praesentium accusantium ipsa voluptatibus ex, porro.
          Repudiandae omnis aperiam quaerat ducimus quo veritatis. Dignissimos
          expedita dolorem explicabo eligendi in Molestiae consequatur pariatur
          iure perferendis dignissimos Repellat deleniti veritatis nisi!
        </p>
        <p>
          Lorem eius pariatur delectus deleniti obcaecati. Consectetur fugit
          maiores sunt qui quos Consequatur delectus culpa earum architecto
          itaque. Reiciendis nulla necessitatibus eum facere at Aspernatur nam
          harum dolorem perferendis debitis
        </p>
        <p>
          Dolor quia maxime sit distinctio ea Cumque tenetur deleniti ipsum
          dolores ab maiores. Labore laboriosam illum provident optio eveniet
          delectus molestiae Pariatur perspiciatis neque magnam nesciunt itaque.
          Rerum velit dignissimos
        </p>
        <p>
          Dolor non dolorum enim a suscipit Molestias voluptatem nulla nulla
          placeat minus quo. A culpa dolorem magnam atque ad Aspernatur quasi
          aperiam libero fugiat mollitia nemo repellendus. Eum quas excepturi!
        </p>
        <p>
          Elit cumque nulla deserunt quaerat reprehenderit. Ducimus natus
          quibusdam blanditiis eveniet id? Neque ratione at alias eius ullam.
          Explicabo laborum inventore culpa hic repellat sapiente ducimus! Harum
          quasi aliquid quae
        </p>
        <p>
          Adipisicing molestiae incidunt distinctio quasi distinctio.
          Consequatur exercitationem explicabo repellat illum cum neque Eos
          repellendus et commodi est voluptatem Rem veritatis suscipit libero
          aspernatur eveniet Soluta perferendis provident enim at.
        </p>
        <p>
          Ipsum accusamus itaque animi distinctio in. Quidem officiis nulla iure
          commodi odio accusantium Nulla earum aliquid pariatur rerum architecto
          laboriosam. Quaerat fugit provident illum velit consequatur. Aut
          asperiores harum sunt.
        </p>
        <p>
          Amet lorem libero corrupti recusandae laborum. Asperiores corporis
          possimus molestiae accusantium illum Iure cupiditate dolore quae
          laudantium quisquam reiciendis, fuga. Eaque nesciunt quaerat quo amet
          nulla laborum Nemo earum similique?
        </p>
        <p>
          Amet tempore eos minima atque incidunt Harum debitis minus eligendi
          fugiat cupiditate? Repellendus totam labore quo atque facilis.
          Perferendis impedit laudantium ex debitis veritatis? Vel voluptatem
          cumque accusamus dolor dolor!
        </p>
        <p>
          Consectetur natus at nulla corporis adipisci. Impedit assumenda minus
          quod vitae deleniti! Ab labore quos impedit harum quod provident
          recusandae Pariatur et tempora esse cum impedit Laboriosam quidem iure
          aut
        </p>
        <p>
          Lorem pariatur esse saepe quibusdam deserunt nostrum? Tempore neque
          doloremque sunt quam illo. Quos recusandae laboriosam dignissimos
          ipsum rerum? Fuga placeat quos nisi provident ex ex maxime est amet.
          Ipsam?
        </p>
        <p>
          Sit dolores esse ea laudantium laborum, dolorem ullam? Voluptatibus
          quae impedit illum perferendis nesciunt. Maiores amet molestias minus
          accusamus excepturi est Enim voluptatibus sapiente laudantium qui
          nostrum quod quisquam Architecto
        </p>
        <p>
          Amet amet debitis provident voluptatum iusto labore. Tenetur suscipit
          labore porro est nesciunt. Maiores totam officiis consequuntur placeat
          laboriosam! Harum repudiandae ipsa excepturi iusto culpa. Voluptates
          officiis repellendus sit ipsa?
        </p>
        <p>
          Ipsum atque commodi dolor a iste Consectetur eveniet eum illo aperiam
          velit optio? At voluptatem aperiam harum soluta quae dignissimos.
          Ipsam ex quis dolorem voluptatum placeat dolore Blanditiis inventore
          provident
        </p>
        <p>
          Consectetur animi dolorem harum iusto neque assumenda excepturi In
          laborum aspernatur voluptatem consequatur repellendus. Repudiandae
          doloremque dolor sunt at eaque ipsam, labore Quia dolores fuga
          dignissimos ipsam fugiat Ad dolor.
        </p>
        <p>
          Sit tempore quasi at natus quod cupiditate veniam illo? Illum
          consequatur eos est blanditiis itaque in. Nihil sed quaerat excepturi
          nemo placeat? Eius perspiciatis fugiat est molestias velit! Voluptate
          vel
        </p>
        <p>
          Lorem sed perspiciatis inventore accusamus consequuntur maxime
          Aspernatur maxime odio provident minus labore earum quia consequuntur!
          Aliquam ad dignissimos tempore fuga et, error esse. Veniam asperiores
          repudiandae deleniti praesentium dicta
        </p>
        <p>
          Adipisicing totam et necessitatibus ut commodi Quidem et ullam
          perspiciatis accusantium debitis corrupti Exercitationem eligendi
          tenetur impedit modi laudantium sit inventore Consequuntur ullam ut
          voluptatum sed harum Nostrum rerum vel
        </p>
        <p>
          Dolor magnam accusamus esse id possimus ad? Placeat magni nobis
          deserunt laborum tempora Rem nobis aspernatur laborum veniam
          asperiores Dolor veritatis blanditiis dolores repellat autem nihil
          odit vel Perferendis itaque
        </p>
        <p>
          Elit quasi sit quis officiis a! Placeat quae quidem exercitationem
          reprehenderit nihil, error! Tenetur aperiam quasi reiciendis nisi quod
          Aut quos quia laudantium ducimus vel! Quod sed incidunt sunt autem.
        </p>
        <p>
          Adipisicing laboriosam similique obcaecati nostrum itaque, quod!
          Nesciunt hic totam illo dolorum hic, sequi animi omnis, aspernatur
          quam amet Iure impedit accusamus saepe aut nisi! Consequatur nostrum
          obcaecati tenetur unde?
        </p>
        <p>
          Ipsum saepe inventore architecto hic consectetur cum, vero Dignissimos
          nobis quae quam et architecto Sed libero porro quibusdam recusandae
          quisquam ad. Rerum voluptates veniam beatae maiores praesentium?
          Aspernatur accusamus necessitatibus!
        </p>
        <p>
          Elit aliquid ipsum voluptates dignissimos soluta. Molestiae nulla
          illum ipsum accusamus elit! Veritatis cupiditate id aliquam quo ipsum.
          Unde mollitia ipsam modi autem quaerat? Ducimus a reiciendis aliquam
          quaerat fuga
        </p>
        <p>
          Dolor ab officia eum optio asperiores Voluptatem suscipit placeat aut
          nihil a. Nemo debitis deleniti adipisci earum qui Accusantium iste
          architecto eveniet natus nulla. Enim architecto adipisci ratione ex
          at?
        </p>
        <p>
          Lorem ipsum molestias nam provident cum ad Omnis nihil dolorum maxime
          voluptas unde. Explicabo totam omnis iure provident laudantium
          Pariatur architecto in explicabo blanditiis quas Perferendis in sequi
          deserunt vel
        </p>
        <p>
          Ipsum temporibus pariatur impedit labore molestiae iste maiores
          officiis est? Et nemo explicabo enim consequuntur hic? Dolorem soluta
          nemo provident ullam eaque Aliquam odio ipsam laborum veniam dolorum
          Enim nam.
        </p>
        <p>
          Elit accusantium magni nam sequi nisi consequatur! Quibusdam impedit
          nobis qui repellendus fugit? Neque excepturi labore totam consequatur
          quia aut? Voluptatem vero consequatur illum voluptate facilis? Odio
          obcaecati quasi atque
        </p>
        <p>
          Sit eos eos similique quo ipsa accusantium Sint consequatur doloribus
          numquam deserunt aut? Facere enim debitis voluptate veniam
          consequuntur. Maxime voluptate praesentium harum corrupti voluptatibus
          at voluptate? Commodi minus fuga
        </p>
        <p>
          Dolor facere facilis odio voluptatum nesciunt ex At odio quibusdam
          amet tempore velit. Dignissimos dolor culpa cumque officia vel Ex
          laudantium perferendis non aspernatur labore Id consectetur veritatis
          modi natus
        </p>
        <p>
          Ipsum sit id qui animi nemo aut aut praesentium Animi iusto dolore
          omnis provident sapiente? Ab fugiat neque harum iure corrupti repellat
          doloremque Ipsam laboriosam ab vel obcaecati neque, obcaecati.
        </p>
        <p>
          Ipsum facilis beatae inventore magnam facere. Dignissimos asperiores
          maxime dignissimos maiores iste eveniet. Vero officiis omnis
          praesentium vitae maxime? Cumque sed officiis alias corrupti qui!
          Quisquam tempore eveniet doloribus necessitatibus.
        </p>
        <p>
          Dolor repellendus qui quod nisi ab omnis. Numquam vel delectus
          molestias tenetur sit Tempora ipsa quia optio a tenetur Eos quos cum
          soluta ratione repellendus! Blanditiis nam magni molestias et?
        </p>
        <p>
          Dolor consequuntur consectetur cupiditate ipsam libero. Voluptas
          debitis sit officiis fugiat dolorum, neque. Aut delectus nobis omnis
          sint veniam! Vitae corrupti excepturi similique ullam nam. Ducimus
          ipsum nam adipisci aperiam!
        </p>
        <p>
          Elit nobis minus nobis officiis ullam reprehenderit! Ea dolorum
          placeat explicabo deserunt animi sint molestias. Optio voluptatem a
          aliquid enim rerum! Dolor similique ipsum molestiae dolore accusamus
          Vitae reprehenderit praesentium
        </p>
        <p>
          Lorem alias numquam quos ad optio. Earum doloremque ipsa error iusto
          atque voluptates Accusamus sapiente repellat quisquam expedita
          officia. Dignissimos excepturi blanditiis numquam et porro perferendis
          voluptates asperiores unde At.
        </p>
        <p>
          Elit debitis neque maiores voluptatibus possimus aliquid Magnam iusto
          odit quia placeat commodi Illo qui ipsum ex non doloremque assumenda
          distinctio dolore Unde distinctio facilis iure consequatur qui
          accusamus. Excepturi.
        </p>
        <p>
          Sit adipisci quibusdam reiciendis modi modi rem! Dignissimos sunt
          dicta mollitia consequatur hic beatae Sequi dolorem harum magnam error
          debitis. Commodi necessitatibus consequuntur fugit aliquam aliquam
          praesentium. Voluptate aliquid nostrum
        </p>
        <p>
          Consectetur aliquid natus quo ab itaque? Cupiditate dolore repellat
          adipisci perferendis obcaecati, quaerat repellendus Quaerat unde quam
          corporis laborum eaque. Illo illum velit expedita iure consequuntur ab
          Ullam inventore adipisci
        </p>
        <p>
          Elit id iure delectus repellat iste? Velit iste harum maxime
          exercitationem neque molestiae tempore distinctio. Eaque alias rem
          rerum at illum Consequatur impedit rem delectus blanditiis doloribus!
          Dolorem dolore iure.
        </p>
        <p>
          Dolor error laboriosam pariatur animi eum Laborum expedita maiores ad
          natus provident. Voluptatibus et expedita placeat eligendi nesciunt
          dolor. Libero explicabo aliquam sit cupiditate natus odio? Nisi quod
          nostrum omnis?
        </p>
        <p>
          Elit amet amet officia numquam ipsam. Lorem doloremque dolorum totam
          temporibus inventore illo Ipsam consequatur praesentium animi autem
          temporibus. Minus impedit ipsam libero totam rerum, in Repellat natus
          iste nostrum
        </p>
        <p>
          Dolor qui laboriosam reprehenderit sequi rem Doloremque dolor quae
          error minus aperiam? Tenetur blanditiis nulla eos nulla illo. Modi
          vero corporis ex nobis unde quasi. Ducimus sapiente necessitatibus
          facilis distinctio
        </p>
        <p>
          Elit alias id recusandae perspiciatis corrupti. Ad veniam sunt quo quo
          voluptatum! Molestias explicabo repellendus dolorum excepturi aperiam!
          Quasi iure officiis iure ex error! Rem modi iure a sint recusandae.
        </p>
        <p>
          Sit adipisci eos temporibus hic optio ea officia accusamus Voluptatum
          natus deserunt eaque ullam eum. Esse possimus quis error debitis
          maiores! Nostrum laborum voluptatem odio iste sint Eius alias sunt?
        </p>
        <p>
          Consectetur quis sit quos officia saepe. Voluptatibus error
          praesentium obcaecati qui ipsum! Dicta laborum laborum obcaecati eius
          deserunt, ex harum! Saepe molestias similique quia quas vel? Laborum
          recusandae repellat perspiciatis
        </p>
        <p>
          Adipisicing reiciendis temporibus quasi placeat ullam fugit, laborum.
          Nam unde laborum alias itaque vitae? Ipsam molestiae quisquam eum
          impedit dicta! Corrupti alias natus eaque illum ea ex Odio nulla
          maiores
        </p>
      </Body>
    </Modal>
  );
}
