import { useEffect, useRef } from "react";
import useVideoContext from "../../hooks/useVideoContext";

export default function AudioTrack({ track }) {
  const { activeSinkId } = useVideoContext();
  const audioEl = useRef(null);

  useEffect(() => {
    audioEl.current = track.attach();
    audioEl.current.setAttribute("data-cy-audio-track-name", track.name);
    document.body.appendChild(audioEl.current);
    return () => track.detach().forEach(el => el.remove());
  }, [track]);

  useEffect(() => {
    if (audioEl.current === null) {
      return null;
    }
    if (audioEl.current.setSinkId) {
      audioEl.current.setSinkId(activeSinkId);
    }
  }, [activeSinkId]);

  return null;
}
