import React from "react";
import styled, { keyframes } from "styled-components";
import { ReadyState } from "react-use-websocket";
import { useWebsocketContext } from "../WebsocketProvider";

const animation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.09;
  }
  100% {
    transform: scale(5);
    opacity: 0;
  }
`;

const Pulse = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.green};
  position: relative;
  background: ${({ status, theme }) =>
    status === "connected" ? theme.colors.green : theme.colors.red};

  :before,
  after {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${({ status }) =>
      status === "connected" ? "#84CE7B" : "#ff5754"};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transform: scale(0.25);
    transform-origin: center center;
    animation: ${animation} 3s linear infinite;
  }

  :after {
    animation-delay: 2s;
  }
`;

function ConnectionStatus() {
  const { readyState } = useWebsocketContext();
  // TODO remove emoji
  const status = readyState === ReadyState.OPEN ? "connected" : "disconnected";

  const alt =
    readyState === ReadyState.OPEN
      ? "You are connected 😎"
      : "You are not connected 😒";

  return <Pulse status={status} alt={alt} />;
}

export default ConnectionStatus;
