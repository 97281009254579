const actions = {
  START_SESSION: 'startSession',
  SESSION_READY: 'sessionReady',
  SESSION_JOINED: 'joinedSession',
  SESSION_ENDED: 'sessionEnded',
  PARTICIPANT_LIST_CHANGED: 'participantList',
  PING: 'ping',
  PONG: 'pong',
  LIST: 'list',
};
export default actions;
